import React from "react";
import { Icon, Row, Card, CardTitle, Col, Divider } from "react-materialize";
import "materialize-css";
import MetaTags from 'react-meta-tags';
const NasiKlienci = () => {
  return (
    <Row>
       
            
            <Col m={6} s={12} push="m3">
            <Card className="orange klienci">
                <div className="center"><h5 className="white-text">Nasi Klienci</h5></div>
                <Divider/>
            </Card>
            <Row>

            <Col m={2} s={3}>
            <img className="klienci"src="https://pngimg.com/uploads/bmw_logo/bmw_logo_PNG19707.png" alt="loading" width="100%" />
            </Col>
            <Col m={2} s={3}>
            <img className="klienci"src="https://pngimg.com/uploads/bmw_logo/bmw_logo_PNG19707.png" alt="loading" width="100%" />
            </Col>
            <Col m={2} s={3}>
            <img className="klienci"src="https://pngimg.com/uploads/bmw_logo/bmw_logo_PNG19707.png" alt="loading" width="100%" />
            </Col>
            <Col m={2} s={3}>
            <img className="klienci"src="https://pngimg.com/uploads/bmw_logo/bmw_logo_PNG19707.png" alt="loading" width="100%" />
            </Col>
            <Col m={2} s={3}>
            <img className="klienci"src="https://pngimg.com/uploads/bmw_logo/bmw_logo_PNG19707.png" alt="loading" width="100%" />
            </Col>
            <Col m={2} s={3}>
            <img className="klienci"src="https://pngimg.com/uploads/bmw_logo/bmw_logo_PNG19707.png" alt="loading" width="100%" />
            </Col>
            <Col m={2} s={3}>
            <img className="klienci"src="https://pngimg.com/uploads/bmw_logo/bmw_logo_PNG19707.png" alt="loading" width="100%" />
            </Col>
            <Col m={2} s={3}>
            <img className="klienci"src="https://pngimg.com/uploads/bmw_logo/bmw_logo_PNG19707.png" alt="loading" width="100%" />
            </Col>
            <Col m={2} s={3}>
            <img className="klienci"src="https://pngimg.com/uploads/bmw_logo/bmw_logo_PNG19707.png" alt="loading" width="100%" />
            </Col>
            <Col m={2} s={3}>
            <img className="klienci"src="https://pngimg.com/uploads/bmw_logo/bmw_logo_PNG19707.png" alt="loading" width="100%" />
            </Col>
            <Col m={2} s={3}>
            <img className="klienci"src="https://pngimg.com/uploads/bmw_logo/bmw_logo_PNG19707.png" alt="loading" width="100%" />
            </Col>
            <Col m={2} s={3}>
            <img className="klienci"src="https://pngimg.com/uploads/bmw_logo/bmw_logo_PNG19707.png" alt="loading" width="100%" />
            </Col>
           
            </Row>
        </Col>
    </Row>
  );
};
export default NasiKlienci;
