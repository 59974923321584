import React from 'react';
import {Table, MediaBox, Icon, Row, Card, CardTitle, Col, Slider, Slide, Caption, Carousel} from 'react-materialize';
import 'materialize-css';
import MetaTags from 'react-meta-tags';

const NaszeRealizacje = () => {
    return(
      <Row>
        <Col
           m={10}
           s={12}
           push="m1"
        >
 <Card
      actions={[
        <a key="1"  aria-label="yourdescription"href="#">This is a link</a>
      ]}
      closeIcon={<Icon>close</Icon>}
      header={<CardTitle image="https://images.unsplash.com/photo-1596986952526-3be237187071?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1502&q=80" />}
      horizontal
      revealIcon={<Icon>more_vert</Icon>}
    >
      Here is the standard card with a horizontal image.
    </Card>
    <Card
      actions={[
        <a key="1"  aria-label="yourdescription"href="#">This is a link</a>
      ]}
      closeIcon={<Icon>close</Icon>}
      header={<CardTitle image="https://images.unsplash.com/photo-1560427183-4efd29c38997?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80" />}
      horizontal
      revealIcon={<Icon>more_vert</Icon>}
    >
      Here is the standard card with a horizontal image.
    </Card>


</Col>
</Row>
)
}
export default NaszeRealizacje;