import waga1 from "./zdjecia/waga_gladka.png";
import waga2 from "./zdjecia/waga_ryflowana_inox.png";
import React from 'react';

const ListaWzor = [
  {
    id: 1,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="" width="100%" /> ,
    img:<img className="imgmaxheigh"alt="loading" src="" width="100%" /> ,
    img2: "",
    img3: "",
    plname: "",
    enname: "",
    pldescription: "  ",
    endescription: " ",
    videoid: " ",
    plopakowania: " ",
    enopakowania: " ",
    plprodukty: " ",
    enprodukty: "  ",
    rodzaj: "brak",
  },
  {
    id: 2,
    imgglowny:<img className="imgmaxheigh"alt="loading" src= "https://www.gsp.it/public/img/sotto_pagine/202079_151034_CJ7CKY.jpg" width="100%" />
     ,
    img:<img className="imgmaxheigh"alt="loading" src="https://www.gsp.it/public/img/sotto_pagine/202079_151034_CJ7CKY.jpg " width="100%" /> ,
    img2: "https://www.gsp.it/public/img/sotto_pagine/202079_151035_XM9KU6.jpg ",
    img3: "https://www.gsp.it/public/img/sotto_pagine/202079_151035_S7VZDE.jpg ",
    plname: "GSP 45 S",
    enname: "GSP 45 S",
    pldescription:
      " Pozioma maszyna pakująca GSP 45 S jest wynikiem ewolucji oraz wielu lat doświadczeń w dziedzinie poziomego pakowania produktów. Jest to niezawodna i wielofunkcyjna maszyna elektroniczna . Niezwykle elastyczna jeżeli chodzi  o zmianę formatów, przystosowana do pakowania produktów spożywczych i innych, zarówno w opakowania jednostkowe jak i opakowania zbiorcze ( tzw. multi-packi) . Maszynę wyróżnia prosta konstrukcja jak również łatwość w utrzymaniu i bezpieczeństwo.",
    endescription:
      " GSP 45 S is the result of our machines evolution and of many experience’s years in the field of horizontal products’ packaging Electronic Horizontal Pillow Pack Wrapper versatile and reliable, very flexible in the format-change, suitable for food and non-food products, both for single-dose and for multiple packages. Cantilever frame and easy accessibility, together with sanitation principles, easy maintenance and safety, it has been constructed for, allows this wrapper to distinguish itself.",
    videoid: "",
    plopakowania: "Poduszka, PillowBag, Tacka, Poduszka z eurowieszakiem",
    enopakowania: "PillowBag, PillowBag with euroslot, tray",
    plprodukty:
      " ciastka, chleb, ciasto, chrupki, chipsy, czekolada, warzywa, pomidory, owoce, wafle, tacki, kontakty elektryczne, wtyczki, przedluzacz, akcesoria meblowe, kredki, eyeliner, dlugopisy, materialy reklamowe, swieczki, scierki, gabki, obrus, zawieszki zapachowe, obroze, tacki aluminiowe, sztucce, multipacki",
    enprodukty:
      " cookies, bread, cake, chips, chocolate, vegetables, tomato, fruits, wafers, trays, power socket,plugs, extension cord, furniture accessories, pencils, eyeliner, pens, advertising accessories, candles, wipes, sponges, tablecloth, car frangance, collar, grill trays, cutleery, multipacks",
    keyword:
      ", cukier, ryż, suszone owocem sól, przyprawy, proszek do prania, karma dla zwierząt, nasiona",
    rodzaj: "Maszyna Pozioma",
  },
  {
    id: 3,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="https://www.gsp.it/public/img/sotto_pagine/202063_103449_6ZW126.jpg" width="100%" />,
    img:<img className="imgmaxheigh"alt="loading" src="https://www.gsp.it/public/img/sotto_pagine/202063_103449_6ZW126.jpg" width="100%" /> ,
    img2: " ",
    img3: " ",
    plname: "GSP 50 S",
    enname: "GSP 50 S",
    endescription:
      " GSP 50 S is the result of our machines' evolution and of many experience's years in the field of horizontal products' packaging. Electronic Horizontal Pillow Pack Wrapper, versatile and reliable, very flexible in the format-change, suitable for food and non food products, both for single-dose and for multiple packages. Cantilever frame and easy accessibility, together with sanitation principles, easy maintenance and safety, it has been constructed for, allows this wrapper to distinguish itself.",
    pldescription:
      " Pozioma maszyna pakująca GSP 50 EVO jest wynikiem ewolucji oraz wielu lat doświadczeń w dziedzinie poziomego pakowania produktów. Jest to maszyna elektroniczna, wielofunkcyjna i solidna. Niezwykle elastyczna jeżeli chodzi  o zmianę formatów, przystosowana do pakowania produktów spożywczych i innych, zarówno w opakowaniach jednostkowych jak i multipack. Maszynę wyróżnia prosta konstrukcja jak również łatwość w utrzymaniu i bezpieczeństwo.",
    videoid: "",
    plopakowania: "Poduszka, PillowBag, Tacka, Poduszka z eurowieszakiem",
    enopakowania: "PillowBag, PillowBag with euroslot, tray",
    plprodukty:
      " ciastka, chleb, ciasto, chrupki, chipsy, czekolada, warzywa, pomidory, owoce, wafle, tacki, kontakty elektryczne, wtyczki, przedluzacz, akcesoria meblowe, kredki, eyeliner, dlugopisy, materialy reklamowe, swieczki, scierki, gabki, obrus, zawieszki zapachowe, obroze, tacki aluminiowe, sztucce, multipacki",
    enprodukty:
      " cookies, bread, cake, chips, chocolate, vegetables, tomato, fruits, wafers, trays, power socket,plugs, extension cord, furniture accessories, pencils, eyeliner, pens, advertising accessories, candles, wipes, sponges, tablecloth, car frangance, collar, grill trays, cutleery, multipacks",
    keyword:
      ", cukier, ryż, suszone owocem sól, przyprawy, proszek do prania, karma dla zwierząt, nasiona",
    rodzaj: "Maszyna Pozioma",
  },
  {
    id: 4,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="https://www.gsp.it/public/img/sotto_pagine/20201014_123738_PMCTLE.jpg" width="100%" />
      ,
    img:<img className="imgmaxheigh"alt="loading" src="https://www.gsp.it/public/img/sotto_pagine/20201014_123738_PMCTLE.jpg" width="100%" /> ,
    img2: "https://www.gsp.it/public/img/sotto_pagine/20201014_123739_2BXMBX.jpg",
    img3: "https://www.gsp.it/public/img/sotto_pagine/20201014_123739_Y8D5C8.jpg",
    plname: "GSP 55 S",
    enname: "GSP 55 S",
    pldescription:
      "Pozioma maszyna pakująca GSP 55 EVO jest wynikiem ewolucji oraz wielu lat doświadczeń w dziedzinie poziomego pakowania produktów. Jest to maszyna elektroniczna, wielofunkcyjna i solidna. Niezwykle elastyczna jeżeli chodzi o zmianę formatów, przystosowana do pakowania produktów zarówno w opakowania jednostkowe jak i multipack. Maszynę wyróżnia prosta konstrukcja jak również łatwość w utrzymaniu i bezpieczeństwo.",
    endescription:
      "GSP 55 EVO is the result of our machines' evolution and of many experience's years in the field of horizontal products' packaging. Electronic Horizontal Pillow Pack Wrapper, versatile and reliable, very flexible in the format-change, suitable for food and non food products, both for single-dose and for multiple packages. Cantilever frame and easy accessibility, together with sanitation (sanification) principles, easy maintenance and safety, it has been constructed for, allows this wrapper to distinguish itself.",
    videoid: " ",
    plopakowania: "Poduszka, PillowBag, Tacka, Poduszka z eurowieszakiem",
    enopakowania: "PillowBag, PillowBag with euroslot, tray",
    plprodukty:
      " ciastka, chleb, ciasto, chrupki, chipsy, czekolada, warzywa, pomidory, owoce, wafle, tacki, kontakty elektryczne, wtyczki, przedluzacz, akcesoria meblowe, kredki, eyeliner, dlugopisy, materialy reklamowe, swieczki, scierki, gabki, obrus, zawieszki zapachowe, obroze, tacki aluminiowe, sztucce, multipacki",
    enprodukty:
      " cookies, bread, cake, chips, chocolate, vegetables, tomato, fruits, wafers, trays, power socket,plugs, extension cord, furniture accessories, pencils, eyeliner, pens, advertising accessories, candles, wipes, sponges, tablecloth, car frangance, collar, grill trays, cutleery, multipacks",
    keyword:
      ", cukier, ryż, suszone owocem sól, przyprawy, proszek do prania, karma dla zwierząt, nasiona",
    rodzaj: "Maszyna Pozioma",
  },
  {
    id: 5,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="https://www.gsp.it/public/img/sotto_pagine/2017621_123830_RNEVNF.jpg" width="100%" />
      ,
    img:<img className="imgmaxheigh"alt="loading" src="https://www.gsp.it/public/img/sotto_pagine/2017621_123830_RNEVNF.jpg" width="100%" /> ,
    img2: "https://www.gsp.it/public/img/sotto_pagine/2017621_123830_52ZODZ.jpg",
    img3: "https://www.gsp.it/public/img/sotto_pagine/2017621_123830_52ZODZ.jpg",
    plname: "GSP 55 BB",
    enname: "GSP 55 BB",
    pldescription:
      "Pozioma maszyna pakująca GSP 55 EVO BB  jest wynikiem ewolucji oraz wielu lat doświadczeń w dziedzinie poziomego pakowania produktów. Jest to maszyna elektroniczna, wielofunkcyjna i solidna. Niezwykle elastyczna jeżeli chodzi o zmianę formatów, przystosowana do pakowania produktów zarówno w opakowania jednostkowe jak i multipack. Maszynę wyróżnia prosta konstrukcja jak również łatwość w utrzymaniu i bezpieczeństwo.",
    endescription:
      "GSP 55 EVO  BB is the result of our machines' evolution and of many experience's years in the field of horizontal products' packaging. Electronic Horizontal Pillow Pack Wrapper, versatile and reliable, very flexible in the format-change, suitable for food and non-food products, both for single-dose and for multiple packages. Cantilever frame and easy accessibility, together with sanitation (sanification) principles, easy maintenance and safety, it has been constructed for, allows this wrapper to distinguish itself.",
    videoid: " ",
    plopakowania: "Poduszka, PillowBag, Tacka, Poduszka z eurowieszakiem",
    enopakowania: "PillowBag, PillowBag with euroslot, tray",
    plprodukty:
      " ciastka, chleb, ciasto, chrupki, chipsy, czekolada, warzywa, pomidory, owoce, wafle, tacki, kontakty elektryczne, wtyczki, przedluzacz, akcesoria meblowe, kredki, eyeliner, dlugopisy, materialy reklamowe, swieczki, scierki, gabki, obrus, zawieszki zapachowe, obroze, tacki aluminiowe, sztucce, multipacki",
    enprodukty:
      " cookies, bread, cake, chips, chocolate, vegetables, tomato, fruits, wafers, trays, power socket,plugs, extension cord, furniture accessories, pencils, eyeliner, pens, advertising accessories, candles, wipes, sponges, tablecloth, car frangance, collar, grill trays, cutleery, multipacks",
    keyword:
      ", cukier, ryż, suszone owocem sól, przyprawy, proszek do prania, karma dla zwierząt, nasiona",
    rodzaj: "Maszyna Pozioma",
  },
  {
    id: 6,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="https://www.gsp.it/public/img/sotto_pagine/2017621_125542_WSJ0SK.jpg" width="100%" />
      ,
    img:<img className="imgmaxheigh"alt="loading" src="https://www.gsp.it/public/img/sotto_pagine/2017621_125542_WSJ0SK.jpg" width="100%" /> ,
    img2: "https://www.gsp.it/public/img/sotto_pagine/2017621_125543_R2G15J.jpg",
    img3: "https://www.gsp.it/public/img/sotto_pagine/2017621_125543_RPKZX1.jpg",
    plname: "GSP 65 EVO",
    enname: "GSP 65 EVO",
    pldescription:
      "Pozioma maszyna pakująca GSP 65 EVO  jest maszyną przemysłową przeznaczoną do pakowania produktów o dłuższym okresie przydatności do spożycia, dzięki systemowi wtrysku gazu oraz rozpylaniu aromatów konserwujących. Jest szczególnie zalecana dla produktów o dużych rozmiarach i znacznej wysokości, jak również do zastosowania folii polietylen/poliolefina . Nabyte przez lata doświadczenie w dziedzinie produkcji maszyn przeznaczonych do pakowania produktów w atmosferze modyfikowanej, pozwoliło nam zbudować maszynę bardzo wydajną i wielofunkcyjną, wykonującą opakowania całkowicie hermetyczne. Elektroniczny system zgrzewu poprzecznego z nożem kroczącym, gwarantuje szybką zmianę formatów oraz wykonywanie opakowań z wgniotami bocznymi. Hermetyczność opakowania może być uzyskana dzięki  długiemu czasowi zgrzewania połączonego z systemem inercji, który przekazuje wysokie ciśnienie folii. Ten sam system znajduje również idealne rozwiązanie w sektorze produktów technicznych z możliwością zgrzewania folii takich jak poliolefina i polietylen. Maszyna GSP 65 EVO jest produkowana również w wersji z podawaniem folii od dołu. W obydwu wersjach maszyna może być wyposażona opcjonalnie w podajnik gładki dostosowany do automatycznego podawania produktów o różnej długości (bez zbieraków).",
    endescription:
      "GSP 65 EVO   is an industrial machine dedicated to packaging long-life products by flushing gas or spraying on preserving aromas. It is specifically indicated for large, high or in-chain products, also using polyethylene/polyolefin film. Years of design experience on modified atmosphere packaging machines led to the creation of a very fast, versatile machine for perfectly airtight packaging. The transversal, box motion, electronically controlled sealing unit guarantees fast changeover and, on demand, gusseted and square shaped packages. The long-time sealing system combined with a low inertia leverage guarantees airtight sealing, putting high pressure on film. This system is ideally applie in the technical product sector, to welding film like polyolefin and polyethylene. On demand, package can have bead seals. The GSP 65 EVO is also available in BB VERSION (reel coming from the bottom). Both versions can be equipped with a belted infeed and with the variable bag length feature.",
    videoid: " ",
    plopakowania: "Poduszka, PillowBag, Tacka, Poduszka z eurowieszakiem",
    enopakowania: "PillowBag, PillowBag with euroslot, tray",
    plprodukty:
      " ciastka, chleb, ciasto, chrupki, chipsy, czekolada, warzywa, pomidory, owoce, wafle, tacki, kontakty elektryczne, wtyczki, przedluzacz, akcesoria meblowe, kredki, eyeliner, dlugopisy, materialy reklamowe, swieczki, scierki, gabki, obrus, zawieszki zapachowe, obroze, tacki aluminiowe, sztucce, multipacki",
    enprodukty:
      " cookies, bread, cake, chips, chocolate, vegetables, tomato, fruits, wafers, trays, power socket,plugs, extension cord, furniture accessories, pencils, eyeliner, pens, advertising accessories, candles, wipes, sponges, tablecloth, car frangance, collar, grill trays, cutleery, multipacks",
    keyword:
      ", cukier, ryż, suszone owocem sól, przyprawy, proszek do prania, karma dla zwierząt, nasiona",
    rodzaj: "Maszyna Pozioma",
  },
  {
    id: 7,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="https://www.gsp.it/public/img/sotto_pagine/2017621_130439_DA7HCM.jpg" width="100%" />,
      
    img:<img className="imgmaxheigh"alt="loading" src="https://www.gsp.it/public/img/sotto_pagine/2017621_130439_DA7HCM.jpg" width="100%" /> ,
    img2: "https://www.gsp.it/public/img/sotto_pagine/2017621_130439_XWMBX0.jpg",
    img3: "https://www.gsp.it/public/img/sotto_pagine/2017621_130440_TDSGK5.jpg",
    plname: "GSP 65 BB",
    enname: "GSP 65 BB",
    pldescription:
      "Pozioma maszyna pakująca GSP 65 EVO BB  jest maszyną przemysłową przeznaczoną do pakowania produktów o dłuższym okresie przydatności do spożycia, dzięki systemowi wtrysku gazu oraz rozpylaniu aromatów konserwujących. Jest szczególnie zalecana dla produktów o dużych rozmiarach i znacznej wysokości, jak również do zastosowania folii polietylen/poliolefina . Nabyte przez lata doświadczenie w dziedzinie produkcji maszyn przeznaczonych do pakowania produktów w atmosferze modyfikowanej, pozwoliło nam zbudować maszynę bardzo wydajną i wielofunkcyjną, wykonującą opakowania całkowicie hermetyczne. Elektroniczny system zgrzewu poprzecznego z nożem kroczącym, gwarantuje szybką zmianę formatów oraz wykonywanie opakowań z wgniotami bocznymi. Hermetyczność opakowania może być uzyskana dzięki  długiemu czasowi zgrzewania połączonego z systemem inercji, który przekazuje wysokie ciśnienie folii. Ten sam system znajduje również idealne rozwiązanie w sektorze produktów technicznych z możliwością zgrzewania folii takich jak poliolefina i polietylen. Maszyna GSP 65 EVO BB jest produkowana również w wersji z podawaniem folii od góry. W obydwu wersjach maszyna może być wyposażona opcjonalnie w podajnik gładki dostosowany do automatycznego podawania produktów o różnej długości (bez zbieraków).",
    endescription:
      "GSP 65 EVO  BB is an industrial machine dedicated to packaging long-life products by flushing gas or spraying on preserving aromas. It is specifically indicated for large, high or in-chain products, also using polyethylene/polyolefin film. Years of design experience on modified atmosphere packaging machines led to the creation of a very fast, versatile machine for perfectly airtight packaging. The transversal, box motion, electronically controlled sealing unit guarantees fast changeover and, on demand, gusseted and square shaped packages. The long-time sealing system combined with a low inertia leverage guarantees airtight sealing, putting high pressure on film. This system is ideally applie in the technical product sector, to welding film like polyolefin and polyethylene. On demand, package can have bead seals. The GSP 65 EVO BB is also available in EVO VERSION (reel coming from the top). Both versions can be equipped with a belted infeed and with the variable bag length feature.",
    videoid: " ",
    plopakowania: "Poduszka, PillowBag, Tacka, Poduszka z eurowieszakiem",
    enopakowania: "PillowBag, PillowBag with euroslot, tray",
    plprodukty:
      " ciastka, chleb, ciasto, chrupki, chipsy, czekolada, warzywa, pomidory, owoce, wafle, tacki, kontakty elektryczne, wtyczki, przedluzacz, akcesoria meblowe, kredki, eyeliner, dlugopisy, materialy reklamowe, swieczki, scierki, gabki, obrus, zawieszki zapachowe, obroze, tacki aluminiowe, sztucce, multipacki",
    enprodukty:
      " cookies, bread, cake, chips, chocolate, vegetables, tomato, fruits, wafers, trays, power socket,plugs, extension cord, furniture accessories, pencils, eyeliner, pens, advertising accessories, candles, wipes, sponges, tablecloth, car frangance, collar, grill trays, cutleery, multipacks",
    keyword:
      ", cukier, ryż, suszone owocem sól, przyprawy, proszek do prania, karma dla zwierząt, nasiona",
    rodzaj: "Maszyna Pozioma",
  },
  {
    id: 8,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="https://www.gsp.it/public/img/sotto_pagine/202055_163056_1XO5XP.jpg" width="100%" />
      ,
    img:<img className="imgmaxheigh"alt="loading" src="https://www.gsp.it/public/img/sotto_pagine/202055_163056_1XO5XP.jpg" width="100%" /> ,
    img2: "",
    img3: "",
    plname: "GSP 75 EVO",
    enname: "GSP 75 EVO",
    pldescription:
      "Pozioma maszyna pakująca GSP 75 EVO jest maszyną niezwykle wytrzymałą i dostosowaną do pracy w systemach automatycznych, ponieważ może obsługiwać wieloosiowe systemy podawania produktu. Nabyte przez lata doświadczenie w dziedzinie produkcji maszyn pakujących pozwoliło nam zbudować maszynę bardzo wydajną i wielofunkcyjną.  Maszyna ta wyróżnia się specjalnymi wzdłużnymi zespołami rolek zgrzewających, umożliwiającymi szybkie zgrzewanie folii.  Stabilność systemu wspomagającego rozwijanie folii i konstrukcja wspornika zapewnia doskonałą produktywność nawet przy intensywnym użytkowaniu.",
    endescription:
      "The GSP 75 EVO is extremely sturdy and suited to operating on automatic systems as it can handle multi-axis loaders. This machine stands out for its special cantable longitudinal sealing units enabling high speed film sealing also when gas resistant seals are required. The reel unwinder's and cantilevered structure's stability ensure excellent productivity even when put to heavy use.",
    videoid: " ",
    plopakowania: "Poduszka, PillowBag, Tacka, Poduszka z eurowieszakiem",
    enopakowania: "PillowBag, PillowBag with euroslot, tray",
    plprodukty:
      " ciastka, chleb, ciasto, chrupki, chipsy, czekolada, warzywa, pomidory, owoce, wafle, tacki, kontakty elektryczne, wtyczki, przedluzacz, akcesoria meblowe, kredki, eyeliner, dlugopisy, materialy reklamowe, swieczki, scierki, gabki, obrus, zawieszki zapachowe, obroze, tacki aluminiowe, sztucce, multipacki",
    enprodukty:
      " cookies, bread, cake, chips, chocolate, vegetables, tomato, fruits, wafers, trays, power socket,plugs, extension cord, furniture accessories, pencils, eyeliner, pens, advertising accessories, candles, wipes, sponges, tablecloth, car frangance, collar, grill trays, cutleery, multipacks",
    keyword:
      ", cukier, ryż, suszone owocem sól, przyprawy, proszek do prania, karma dla zwierząt, nasiona",
    rodzaj: "Maszyna Pozioma, Flow-pack ",
  },
  {
    id: 9,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="https://www.essegi.com/wp-content/uploads/2019/08/f700-dosatore-tazze.jpg" width="100%" />,
      
    img:<img className="imgmaxheigh"alt="loading" src="https://www.essegi.com/wp-content/uploads/2019/08/f700-dosatore-tazze.jpg" width="100%" /> ,

    img2: "",
    img3: "",
    plname: "F700",
    enname: "F700",
    pldescription:
      "Automatyczna pionowa maszyna pakująca model F700 przeznaczona jest do pakowania krótkiego makaronu, ziaren, zbóż, kawy, soli, suszonych owoców, słodyczy oraz innych produktów spożywczych. Maszyna F700 doskonale radzi sobie również z pakowaniem ziemi, nawozów, peletu oraz innych substancji granulowanych, napełniając worki o pojemności do 5 kg. Jedną z największych zalet pionowej maszyny F700 jest jej wydajność. Parametry urządzenia pozwalają na sprawne i szybkie pionowe pakowanie do 40 sztuk na minutę.  Wyposażona w intuicyjny ekran dotykowy, umożliwiający zarządzanie poszczególnymi funkcjami. Maszyny F700 obsługują rolki o szerokości od 150 do 700 mm i maksymalnej średnicy 600 mm.",
    endescription:
      "Automatyczna pionowa maszyna pakująca model F700 przeznaczona jest do pakowania krótkiego makaronu, ziaren, zbóż, kawy, soli, suszonych owoców, słodyczy oraz innych produktów spożywczych. Pakowarka doskonale radzi sobie również z pakowaniem ziemi, nawozów, peletu oraz innych substancji granulowanych, napełniając worki o pojemności do 5 kg. Jedną z największych zalet pionowej maszyny F700 jest jej wydajność. Parametry urządzenia pozwalają na sprawne i szybkie pionowe pakowanie do 40 sztuk na minutę.  Wyposażona w intuicyjny ekran dotykowy, umożliwiający zarządzanie poszczególnymi funkcjami. Maszyny pakujące pionowe z naszej oferty posiadają automatyczne czujniki wyczerpania folii oraz urządzenia umożliwiające szybką wymianę rolki, dzięki czemu proces pakowania przebiega szybko i sprawnie. Maszyny F700 obsługują rolki o szerokości od 150 do 700 mm i maksymalnej średnicy 600 mm.",
    videoid: " ",
    plopakowania:
      "Poduszka, Poduszka z wgniotami bocznymi, opakowanie kwadratowe",
    enopakowania: "PillowBag, PillowBag with gussets, square bottom",

    plprodukty:
      "pellet, karma dla zwierzat, nawoz, nasiona, proszek do prania, koncentraty, sruby i nakretki, makaron, suszone owoce, mrozone warzywa i owoce, kawa ziarnista, kakao, sol, ryz, itp",
    enprodukty:
      "wood pellets, pet food, granular fertilizer, seeds, washing powder, master batches, olive residues, nuts and bolts, short pasta, dried fruit, frozen vegetables, granular coffee / cocoa, salt, pulses, rice etc.",
    keyword: " ",
    rodzaj: "maszyna pionowa",
  },
  {
    id: 10,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="https://www.essegi.com/wp-content/uploads/2019/08/confezionatrice-f1000-bilancia-a-nastro-bn-k.jpg" width="100%" />,
    img:<img className="imgmaxheigh"alt="loading" src="https://www.essegi.com/wp-content/uploads/2019/08/confezionatrice-f1000-bilancia-a-nastro-bn-k.jpg" width="100%" /> ,

    img2: "",
    img3: "",
    plname: "F1000",
    enname: "F1000",
    pldescription:
      "Pionowa maszyna pakująca model F1000 jest przeznaczona do formowania, napełniania i zgrzewania opakowań formowanych z folii odwijanej z pojedynczej rolki umiejscowioną z tyłu maszyny. W zależności od potrzeb klienta dobieramy odpowiedni dla niego system dozowania. Zapraszamy do zapoznania się z ofertą.",
    endescription:
      "The F1000 vertical packaging machine is designed to form, fill and seal wrapped packaging from a single roll film located at the rear of the machine. Depending on the customer's needs, we select the appropriate dosing system. We invite you to familiarize yourself with the offer.",
    videoid: " ",
    plopakowania:
      "Poduszka, Poduszka z wgniotami bocznymi, opakowanie kwadratowe",
    enopakowania: "PillowBag, PillowBag with gussets, square bottom",

    plprodukty:
      "pellet, karma dla zwierzat, nawoz, nasiona, proszek do prania, koncentraty, sruby i nakretki, makaron, suszone owoce, mrozone warzywa i owoce, kawa ziarnista, kakao, sol, ryz, itp",
    enprodukty:
      "wood pellets, pet food, granular fertilizer, seeds, washing powder, master batches, olive residues, nuts and bolts, short pasta, dried fruit, frozen vegetables, granular coffee / cocoa, salt, pulses, rice etc.",
    keyword: " ",
    rodzaj: "maszyna pionowa",
  },
  {
    id: 11,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="https://www.essegi.com/wp-content/uploads/2019/08/confezionatrice-f1000-motion-sueso.jpg" width="100%" />,
    img:<img className="imgmaxheigh"alt="loading" src="https://www.essegi.com/wp-content/uploads/2019/08/confezionatrice-f1000-motion-sueso.jpg" width="100%" /> ,

    img2: "",
    img3: "",
    plname: "F1000",
    enname: "F1000",
    pldescription:
      "Pionowa maszyna pakująca model F1000 jest przeznaczona do formowania, napełniania i zgrzewania opakowań formowanych z folii odwijanej z pojedynczej rolki umiejscowioną z tyłu maszyny. W zależności od potrzeb klienta dobieramy odpowiedni dla niego system dozowania. Zapraszamy do zapoznania się z ofertą.",
    endescription:
      "The F1000 vertical packaging machine is designed to form, fill and seal wrapped packaging from a single roll film located at the rear of the machine. Depending on the customer's needs, we select the appropriate dosing system. We invite you to familiarize yourself with the offer.",
    videoid: " ",
    plopakowania:
      "Poduszka, Poduszka z wgniotami bocznymi, opakowanie kwadratowe",
    enopakowania: "PillowBag, PillowBag with gussets, square bottom",

    plprodukty:
      "pellet, karma dla zwierzat, nawoz, nasiona, proszek do prania, koncentraty, sruby i nakretki, makaron, suszone owoce, mrozone warzywa i owoce, kawa ziarnista, kakao, sol, ryz, itp",
    enprodukty:
      "wood pellets, pet food, granular fertilizer, seeds, washing powder, master batches, olive residues, nuts and bolts, short pasta, dried fruit, frozen vegetables, granular coffee / cocoa, salt, pulses, rice etc.",
    keyword: " ",
    rodzaj: "maszyna pionowa",
  },
  {
    id: 12,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="https://www.essegi.com/wp-content/uploads/2019/08/dsc_0006-1.jpg" width="100%" />
      ,
    img:<img className="imgmaxheigh"alt="loading" src="https://www.essegi.com/wp-content/uploads/2019/08/dsc_0006-1.jpg" width="100%" /> ,
    img2: " ",
    img3: "",
    plname: "F1200",
    enname: "F1200",
    pldescription:
      " Pionowa maszyna pakująca F1200 to nowoczesna, w pełni zautomatyzowana maszyna pakująca, przeznaczona do formownia, napełniania oraz zgrzewania opakowań i worków o dużej pojemności (do 50 kg). Podobnie jak inne maszyny pakujące pionowe z naszej oferty, również model F1200 doskonale sprawdza się przy pakowaniu produktów spożywczych, np.: makaronu, ziaren, kawy, zboża, soli, cukru, przypraw, słodyczy, owoców oraz innych produktów codziennego użytku. Jest niezastąpiona także jako maszyna pakująca w worki – z ziemią, karmą dla zwierząt, peletem i pozostałymi produktami granulowanymi. Maszyna  do pakowania F1200 wyposażona jest w łatwy w obsłudze i czytelny ekran dotykowy, dzięki któremu sterowanie poszczególnymi procesami pracy maszyny pakującej staje się jeszcze łatwiejsze. Jak przystało na nowoczesne pionowe maszyny pakujące F1200 posiada pamięć wewnętrzną, pozwalającą na zapisywanie ustawień pakowania poszczególnych produktów, co znacznie skraca czas pakowania. Maszyna posiada automatyczne centrowanie folii oraz nadruku, jest także wyposażona w urządzenie umożliwiające szybką wymianę rolek. Zapraszamy do zapoznania się z ofertą.",
    endescription: "We invite you to familiarize yourself with the offer.",
    videoid: " ",
    plopakowania:
      "Poduszka, Poduszka z wgniotami bocznymi, opakowanie kwadratowe",
    enopakowania: "PillowBag, PillowBag with gussets, square bottom",

    plprodukty:
      "pellet, karma dla zwierzat, nawoz, nasiona, proszek do prania, koncentraty, sruby i nakretki, makaron, suszone owoce, mrozone warzywa i owoce, kawa ziarnista, kakao, sol, ryz, itp",
    enprodukty:
      "wood pellets, pet food, granular fertilizer, seeds, washing powder, master batches, olive residues, nuts and bolts, short pasta, dried fruit, frozen vegetables, granular coffee / cocoa, salt, pulses, rice etc.",
    keyword: " ",
    rodzaj: "maszyna pionowa",
  },
  {
    id: 13,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="https://www.essegi.com/wp-content/uploads/2019/08/macchina-f-1200-reverse.jpg" width="100%" />,
    img:<img className="imgmaxheigh"alt="loading" src="https://www.essegi.com/wp-content/uploads/2019/08/macchina-f-1200-reverse.jpg" width="100%" /> ,
    img2: " ",
    img3: "",
    plname: "F1200 reverse",
    enname: "F1200 reverse",
    pldescription:
      "Pionowa maszyna pakująca F1200 to nowoczesna, w pełni zautomatyzowana maszyna pakująca, przeznaczona do formownia, napełniania oraz zgrzewania opakowań i worków o dużej pojemności (do 50 kg). Podobnie jak inne maszyny pakujące pionowe z naszej oferty, również model F1200 doskonale sprawdza się przy pakowaniu produktów spożywczych, np.: makaronu, ziaren, kawy, zboża, soli, cukru, przypraw, słodyczy, owoców oraz innych produktów codziennego użytku. Jest niezastąpiona także jako maszyna pakująca w worki – z ziemią, karmą dla zwierząt, peletem i pozostałymi produktami granulowanymi. Maszyna  do pakowania F1200 wyposażona jest w łatwy w obsłudze i czytelny ekran dotykowy, dzięki któremu sterowanie poszczególnymi procesami pracy maszyny pakującej staje się jeszcze łatwiejsze. Jak przystało na nowoczesne pionowe maszyny pakujące F1200 posiada pamięć wewnętrzną, pozwalającą na zapisywanie ustawień pakowania poszczególnych produktów, co znacznie skraca czas pakowania. Maszyna posiada automatyczne centrowanie folii oraz nadruku, jest także wyposażona w urządzenie umożliwiające szybką wymianę rolek. Zapraszamy do zapoznania się z ofertą.",
    endescription: "",
    videoid: " ",
    plopakowania:
      "Poduszka, Poduszka z wgniotami bocznymi, opakowanie kwadratowe",
    enopakowania: "PillowBag, PillowBag with gussets, square bottom",

    plprodukty:
      "pellet, karma dla zwierzat, nawoz, nasiona, proszek do prania, koncentraty, sruby i nakretki, makaron, suszone owoce, mrozone warzywa i owoce, kawa ziarnista, kakao, sol, ryz, itp",
    enprodukty:
      "wood pellets, pet food, granular fertilizer, seeds, washing powder, master batches, olive residues, nuts and bolts, short pasta, dried fruit, frozen vegetables, granular coffee / cocoa, salt, pulses, rice etc.",
    keyword: " ",
    rodzaj: "maszyna pionowa",
  },
  {
    id: 14,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="https://www.essegi.com/wp-content/uploads/2019/08/f1300-1.jpg" width="100%" />,
    img:<img className="imgmaxheigh"alt="loading" src="https://www.essegi.com/wp-content/uploads/2019/08/f1300-1.jpg" width="100%" />,
    img2: " ",
    img3: "",
    plname: "F1300",
    enname: "F1300",
    pldescription:
      "Pionowa maszyna pakująca F1300 to nowoczesna, w pełni zautomatyzowana maszyna pakująca, przeznaczona do formownia, napełniania oraz zgrzewania opakowań i worków o dużej pojemności (do 50 kg). Podobnie jak inne maszyny pakujące pionowe z naszej oferty, również model F1200 doskonale sprawdza się przy pakowaniu produktów spożywczych, np.: makaronu, ziaren, kawy, zboża, soli, cukru, przypraw, słodyczy, owoców oraz innych produktów codziennego użytku. Jest niezastąpiona także jako maszyna pakująca w worki – z ziemią, karmą dla zwierząt, peletem i pozostałymi produktami granulowanymi. Maszyna  do pakowania F1200 wyposażona jest w łatwy w obsłudze i czytelny ekran dotykowy, dzięki któremu sterowanie poszczególnymi procesami pracy maszyny pakującej staje się jeszcze łatwiejsze. Jak przystało na nowoczesne pionowe maszyny pakujące F1200 posiada pamięć wewnętrzną, pozwalającą na zapisywanie ustawień pakowania poszczególnych produktów, co znacznie skraca czas pakowania. Maszyna posiada automatyczne centrowanie folii oraz nadruku, jest także wyposażona w urządzenie umożliwiające szybką wymianę rolek. Zapraszamy do zapoznania się z ofertą.",
    endescription: "We invite you to familiarize yourself with the offer.",
    videoid: " ",
    plopakowania:
      "Poduszka, Poduszka z wgniotami bocznymi, opakowanie kwadratowe",
    enopakowania: "PillowBag, PillowBag with gussets, square bottom",

    plprodukty:
      "pellet, karma dla zwierzat, nawoz, nasiona, proszek do prania, koncentraty, sruby i nakretki, makaron, suszone owoce, mrozone warzywa i owoce, kawa ziarnista, kakao, sol, ryz, itp",
    enprodukty:
      "wood pellets, pet food, granular fertilizer, seeds, washing powder, master batches, olive residues, nuts and bolts, short pasta, dried fruit, frozen vegetables, granular coffee / cocoa, salt, pulses, rice etc.",
    keyword: " ",
    rodzaj: "maszyna pionowa",
  },
  {
    id: 15,
    imgglowny:<img className="imgmaxheigh"alt="loading" src={waga1}  width="100%" />,
    img:<img className="imgmaxheigh"alt="loading" src={waga1} width="100%" />,
    img2: " ",
    img3: "",
    plname: "Waga wielogłowicowa",
    enname: "multihead weigher (plain)",
    pldescription:
      "Waga wielogłowicowa gładka jest przeznaczona przede wszystkim do ważenia produktów sypkich spożywczych i przemysłowych np. chipsy, orzeszki, chrupki, popcorn, nasiona, makaron, herbata,",
    endescription: "Multi-head plain weigher is designed primarily for weighing loose food and industrial products, e.g. chips, nuts, crisps, popcorn, seeds, pasta, tea.",
    videoid: " ",
    plopakowania:
      "Poduszka, Poduszka z wgniotami bocznymi, opakowanie kwadratowe, worek, tacka, karton",
    enopakowania: "PillowBag, PillowBag with gussets, square bottom, bag, tray, carton box",
    plprodukty:
      "pellet, karma dla zwierzat, nawoz, nasiona, sruby i nakretki, makaron, suszone owoce, kawa ziarnista, sol, ryz, itp",
    enprodukty:
      "chips, nuts, crisps, popcorn, seeds, pasta, tea, wood pellets, pet food, granular fertilizer, seeds, nuts and bolts, short pasta, dried fruit, granular coffee, salt, rice etc.",
    keyword: "",
    rodzaj: "waga",
  },
  {
    id: 16,
    imgglowny:<img className="imgmaxheigh"alt="loading" src={waga2}  width="100%" />,
    img:<img className="imgmaxheigh"alt="loading" src={waga2}  width="100%" /> ,
    img2: " ",
    img3: "",
    plname: "Waga ryflowana",
    enname: "multihead weigher (dimple)",
    pldescription:
    "Waga wielogłowicowa ryflowana jest przeznaczona przede wszystkim do ważenia produktów lepkich, mających tendencję do przywierania do powierzchni np. mięso, mroone warzywa i owoce",
  endescription: "Multi-head dimple weigher is designed primarily for weighing viscous products that tend to stick to the surface, e.g. meat, frozen vegetables and fruits",
  videoid: " ",
    plopakowania:
      "Poduszka, Poduszka z wgniotami bocznymi, opakowanie kwadratowe, worek, tacka, karton",
    enopakowania: "PillowBag, PillowBag with gussets, square bottom, bag, tray, carton box",
    plprodukty:
      "pellet, karma dla zwierzat, nawoz, nasiona, proszek do prania, koncentraty, sruby i nakretki, makaron, suszone owoce, mrozone warzywa i owoce, kawa ziarnista, kakao, sol, ryz, itp",
    enprodukty:
      "wood pellets, pet food, granular fertilizer, seeds, washing powder, master batches, olive residues, nuts and bolts, short pasta, dried fruit, frozen vegetables, granular coffee / cocoa, salt, pulses, rice etc.",
    keyword: "",
    rodzaj: "waga",
  },
  {
    id: 17,
    imgglowny:<img className="imgmaxheigh" alt="loading" src="https://www.essegi.com/wp-content/uploads/2019/09/bilancia-nastro-bn-k-1.jpg" width="100%" />
      ,
    img1:<img className="imgmaxheigh"alt="loading" src="https://www.essegi.com/wp-content/uploads/2019/09/bilancia-nastro-bn-k-2.jpg" width="100%" /> ,
    img2: " ",
    img3: "",
    plname: "Waga B/N-K",
    enname: "waga",
    pldescription:
      " Pojedyncza elektroniczna waga. ",
    endescription: "We invite you to familiarize yourself with the offer.",
    videoid: " ",
    plopakowania:
      "Poduszka, Poduszka z wgniotami bocznymi, opakowanie kwadratowe",
    enopakowania: "PillowBag, PillowBag with gussets, square bottom",

    plprodukty:
      "pellet, karma dla zwierzat, nawoz, nasiona, proszek do prania, koncentraty, sruby i nakretki, makaron, suszone owoce, mrozone warzywa i owoce, kawa ziarnista, kakao, sol, ryz, itp",
    enprodukty:
      "wood pellets, pet food, granular fertilizer, seeds, washing powder, master batches, olive residues, nuts and bolts, short pasta, dried fruit, frozen vegetables, granular coffee / cocoa, salt, pulses, rice etc.",
    keyword: "",
    rodzaj: "waga",
  },
  {
    id: 18,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="https://www.essegi.com/wp-content/uploads/2019/09/bilancia-nastro-bn-k-2.jpg" width="100%" /> ,
    img:<img className="imgmaxheigh"alt="loading" src="https://www.essegi.com/wp-content/uploads/2019/09/bilancia-nastro-bn-k-2.jpg" width="100%" /> ,
    img2: " ",
    img3: "",
    plname: "Waga B/N-K podwójna",
    enname: "waga",
    pldescription:
      "Zapraszamy do zapoznania się z ofertą.",
    endescription: "We invite you to familiarize yourself with the offer.",
    videoid: " ",
    plopakowania:
      "Poduszka, Poduszka z wgniotami bocznymi, opakowanie kwadratowe",
    enopakowania: "PillowBag, PillowBag with gussets, square bottom",

    plprodukty:
      "pellet, karma dla zwierzat, nawoz, nasiona, proszek do prania, koncentraty, sruby i nakretki, makaron, suszone owoce, mrozone warzywa i owoce, kawa ziarnista, kakao, sol, ryz, itp",
    enprodukty:
      "wood pellets, pet food, granular fertilizer, seeds, washing powder, master batches, olive residues, nuts and bolts, short pasta, dried fruit, frozen vegetables, granular coffee / cocoa, salt, pulses, rice etc.",
    keyword: "",
    rodzaj: "waga",
  },
  {
    id: 19,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
   img1:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
    img2: "",
    img3: "",
    plname: "",
    enname: "",
    pldescription: "  ",
    endescription: " ",
    videoid: " ",
    plopakowania: " ",
    enopakowania: " ",
    plprodukty: " ",
    enprodukty: "  ",
    rodzaj: "brak",
  },
  {
    id: 20,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
   img1:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
    img2: "",
    img3: "",
    plname: "",
    enname: "",
    pldescription: "  ",
    endescription: " ",
    videoid: " ",
    plopakowania: " ",
    enopakowania: " ",
    plprodukty: " ",
    enprodukty: "  ",
    rodzaj: "brak",
  },
  {
    id: 21,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
   img1:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
    img2: "",
    img3: "",
    plname: "",
    enname: "",
    pldescription: "  ",
    endescription: " ",
    videoid: " ",
    plopakowania: " ",
    enopakowania: " ",
    plprodukty: " ",
    enprodukty: "  ",
    rodzaj: "brak",
  },
  {
    id: 1,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
   img1:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
    img2: "",
    img3: "",
    plname: "",
    enname: "",
    pldescription: "  ",
    endescription: " ",
    videoid: " ",
    plopakowania: " ",
    enopakowania: " ",
    plprodukty: " ",
    enprodukty: "  ",
    rodzaj: "brak",
  },
  {
    id: 1,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
   img1:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
    img2: "",
    img3: "",
    plname: "",
    enname: "",
    pldescription: "  ",
    endescription: " ",
    videoid: " ",
    plopakowania: " ",
    enopakowania: " ",
    plprodukty: " ",
    enprodukty: "  ",
    rodzaj: "brak",
  },
  {
    id: 1,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
   img1:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
    img2: "",
    img3: "",
    plname: "",
    enname: "",
    pldescription: "  ",
    endescription: " ",
    videoid: " ",
    plopakowania: " ",
    enopakowania: " ",
    plprodukty: " ",
    enprodukty: "  ",
    rodzaj: "brak",
  },
  {
    id: 1,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
   img1:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
    img2: "",
    img3: "",
    plname: "",
    enname: "",
    pldescription: "  ",
    endescription: " ",
    videoid: " ",
    plopakowania: " ",
    enopakowania: " ",
    plprodukty: " ",
    enprodukty: "  ",
    rodzaj: "brak",
  },
  {
    id: 1,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
   img1:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
    img2: "",
    img3: "",
    plname: "",
    enname: "",
    pldescription: "  ",
    endescription: " ",
    videoid: " ",
    plopakowania: " ",
    enopakowania: " ",
    plprodukty: " ",
    enprodukty: "  ",
    rodzaj: "brak",
  },
  {
    id: 1,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
   img1:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
    img2: "",
    img3: "",
    plname: "",
    enname: "",
    pldescription: "  ",
    endescription: " ",
    videoid: " ",
    plopakowania: " ",
    enopakowania: " ",
    plprodukty: " ",
    enprodukty: "  ",
    rodzaj: "brak",
  },
  {
    id: 1,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
   img1:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
    img2: "",
    img3: "",
    plname: "",
    enname: "",
    pldescription: "  ",
    endescription: " ",
    videoid: " ",
    plopakowania: " ",
    enopakowania: " ",
    plprodukty: " ",
    enprodukty: "  ",
    rodzaj: "brak",
  },
  {
    id: 1,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
   img1:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
    img2: "",
    img3: "",
    plname: "",
    enname: "",
    pldescription: "  ",
    endescription: " ",
    videoid: " ",
    plopakowania: " ",
    enopakowania: " ",
    plprodukty: " ",
    enprodukty: "  ",
    rodzaj: "brak",
  },
  {
    id: 1,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
   img1:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
    img2: "",
    img3: "",
    plname: "",
    enname: "",
    pldescription: "  ",
    endescription: " ",
    videoid: " ",
    plopakowania: " ",
    enopakowania: " ",
    plprodukty: " ",
    enprodukty: "  ",
    rodzaj: "brak",
  },
  {
    id: 1,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
   img1:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
    img2: "",
    img3: "",
    plname: "",
    enname: "",
    pldescription: "  ",
    endescription: " ",
    videoid: " ",
    plopakowania: " ",
    enopakowania: " ",
    plprodukty: " ",
    enprodukty: "  ",
    rodzaj: "brak",
  },
  {
    id: 1,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
   img1:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
    img2: "",
    img3: "",
    plname: "",
    enname: "",
    pldescription: "  ",
    endescription: " ",
    videoid: " ",
    plopakowania: " ",
    enopakowania: " ",
    plprodukty: " ",
    enprodukty: "  ",
    rodzaj: "brak",
  },
  {
    id: 1,
    imgglowny:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
   img1:<img className="imgmaxheigh"alt="loading" src="" width="100%" />,
    img2: "",
    img3: "",
    plname: "",
    enname: "",
    pldescription: "  ",
    endescription: " ",
    videoid: " ",
    plopakowania: " ",
    enopakowania: " ",
    plprodukty: " ",
    enprodukty: "  ",
    rodzaj: "brak",
  },
];
export default ListaWzor;
