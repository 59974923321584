import {
  NavItem,
  Navbar,
  Icon,
  Button,
  Col,
  CardTitle,
  Card,
  Row,
  CardPanel,
  MediaBox,
  Divider,
  Modal,
  DatePicker,
  CollapsibleItem,
  Collapsible,
  Form,
  Toast,
} from "react-materialize";
import "materialize-css";
import React, { Component } from "react";
import call from "./icons/call.jpg";
const Wszystko = () => {
  return (
    <div>
      <Row>
        <Col s={12} m={6} push="m3">
          <h3>opis wszystkich maszyn</h3>
        </Col>
        <Row/>
        <Col s={12} m={6} l={2} push="m3 l2"><Button className="maszyny">cos tam</Button></Col>
        <Col s={12} m={6} l={2} push="m3 l2"><Button className="maszyny">cos tam</Button></Col>
        <Col s={12} m={6} l={2} push="m3 l2"><Button className="maszyny">cos tam</Button></Col>
        <Col s={12} m={6} l={2} push="m3 l2"><Button className="maszyny">cos tam</Button></Col>
        <Col s={12} m={6} l={2} push="m3 l2"><Button className="maszyny">cos tam</Button></Col>
        <Col s={12} m={6} l={2} push="m3 l2"><Button className="maszyny">cos tam</Button></Col>
        <Col s={12} m={6} l={2} push="m3 l2"><Button className="maszyny">cos tam</Button></Col>
        <Col s={12} m={6} l={2} push="m3 l2"><Button className="maszyny">cos tam</Button></Col>
      </Row>
    </div>
  );
};

export default Wszystko;
