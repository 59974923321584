import { Component } from "react";

class globals extends Component { 
     constructor() {
    super();

    this.state = {
      lang: null,
    };
  }
}
  export default globals