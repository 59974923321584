import React, {Component} from 'react';
import {NavItem, Navbar, Icon, Dropdown, Divider, Button} from 'react-materialize';
import { Redirect } from 'react-router-dom'
import 'materialize-css';
import globals from "./globals";
import Logo from "./icons/MadejLogo.svg"
import MetaTags from 'react-meta-tags';




class Nawigacja extends Component {

  constructor() {
    super();

    this.state = {
      lang: localStorage.getItem('lang')
    };
  }

handleClickEn = () => {
  localStorage.setItem('lang', "en");

  //this.setState({ lang: "en" });
  window.location.href = 'homeen';
 
}
handleClickPl = () => {
  //this.setState({ lang: "pl" });
  localStorage.setItem('lang', "pl");
  window.location.href = '/homepl';
}
render(){
if (this.state.lang == "en") 
return(
<Navbar //className="orange darken-1 darken-2 hide-on-med-and-down z-depth-3"
className="navbarmadejpak"
  alignLinks="right"
  brand={ <img src={Logo} alt="loading" height="95px" />}
    //<p1 className="brand-logo white-text ">En PL Madej-Pak</p1>}
  leftLogo
  menuIcon={<p1 className="white-text btn  ">MENU</p1>}
  options={{
    draggable: true,
    edge: 'left',
    inDuration: 250,
    onCloseEnd: null,
    onCloseStart: null,
    onOpenEnd: null,
    onOpenStart: null,
    outDuration: 200,
    preventScrolling: true
  }}
>
<NavItem className=" przycisk_nawigacja z-depth-2" aria-label="yourdescription" href="home">
<div className="btntxt">
      Home
      </div>
    </NavItem> 
   
    <NavItem className=" przycisk_nawigacja z-depth-2" aria-label="yourdescription" href="wyszukiwarka">
      Search
    </NavItem>  <Dropdown
    id="Dropdown_8"

    options={{
      alignment: 'left',
      autoTrigger: true,
      closeOnClick: true,
      constrainWidth: true,
      container: null,
      coverTrigger: true,
      hover: false,
      inDuration: 150,
      onCloseEnd: null,
      onCloseStart: null,
      onOpenEnd: null,
      onOpenStart: null,
      outDuration: 250
    }}
    trigger={<a className=" przycisk_nawigacja z-depth-2">something{' '}</a>}
  >
    <a className="black-text  " aria-label="yourdescription" href="maszyny_poziome">
    Maszyny Poziome
    </a>
    <Divider />
    <a  className="black-text  " aria-label="yourdescription" href="maszyny_pionowe">
    Maszyny Pionowe
    </a>
    
  </Dropdown>

  <Dropdown
    id="Dropdown_99"
    options={{
      alignment: 'left',
      autoTrigger: true,
      closeOnClick: true,
      constrainWidth: true,
      container: null,
      coverTrigger: true,
      hover: false,
      inDuration: 150,
      onCloseEnd: null,
      onCloseStart: null,
      onOpenEnd: null,
      onOpenStart: null,
      outDuration: 250
    }}
    trigger={<a className=" przycisk_nawigacja z-depth-2">something else{' '}</a>}
  >
    <a className="black-text" aria-label="yourdescription" href="maszyny_poziome">
      Wagi
    </a>
    <Divider />
    <a aria-label="yourdescription" href="contact">
    Kartoniarki
    </a>
    <Divider />
    <a aria-label="yourdescription" href="contact">
    Owijarki
    </a>
    <Divider />
    <a aria-label="yourdescription" href="maszyny_pionowe">
    Systemy paletyzacji
    </a>
  </Dropdown>
    <NavItem className=" przycisk_nawigacja z-depth-2" aria-label="yourdescription" href="serwis">
      Servis
    </NavItem>
    <NavItem className=" przycisk_nawigacja z-depth-2" aria-label="yourdescription" href="certyfikaty">
      Certificates
    </NavItem>
    <NavItem className=" przycisk_nawigacja z-depth-2 " aria-label="yourdescription" href="kariera">
    Career
    </NavItem>

    <NavItem className=" przycisk_nawigacja z-depth-2" aria-label="yourdescription" href="about">
      About
    
    </NavItem>
 
   

  <NavItem className=" przycisk_nawigacja z-depth-2" aria-label="yourdescription" href="contact">
     Contact
    </NavItem>
<Button  onClick={(e) => this.handleClickPl(e)} className="red">pl</Button>
<Button  onClick={(e) => this.handleClickEn(e)} className="blue">en</Button>
</Navbar>
)
else return (

<Navbar //className="orange darken-1 darken-2 hide-on-med-and-down z-depth-3"
className="navbarmadejpak"
  alignLinks="right"
  brand={ <img src={Logo} alt="loading" height="95px"/>}
    //<p1 className="brand-logo white-text ">En PL Madej-Pak</p1>}
  leftLogo
  menuIcon={<div className="menuAll"><div className="menu orange-text">MENU</div><div className="kropeczki orange-text">...</div></div> }
  options={{
    draggable: true,
    edge: 'left',
    inDuration: 250,
    onCloseEnd: null,
    onCloseStart: null,
    onOpenEnd: null,
    onOpenStart: null,
    outDuration: 200,
    preventScrolling: true
  }}
>
<div className="hide-on-med-and-up">
<img src={Logo} alt="loading" />
</div>
<NavItem className=" przycisk_nawigacja z-depth-2" aria-label="home" href="home">
     <div className="white-text">Home</div> 
    </NavItem> 
   
    {/* <NavItem className=" przycisk_nawigacja z-depth-2" aria-label="yourdescription" href="wyszukiwarka">
      <div className="white-text">Wyszukaj Maszyny</div> 
    </NavItem>   */}
     <NavItem className=" przycisk_nawigacja z-depth-2" aria-label="yourdescription" href="targi">
      <div className="white-text">Wydarzenia</div> 
    </NavItem>
    <Dropdown
    id="Dropdown_81"

    options={{
      alignment: 'left',
      autoTrigger: true,
      closeOnClick: true,
      constrainWidth: true,
      container: null,
      coverTrigger: true,
      hover: false,
      inDuration: 150,
      onCloseEnd: null,
      onCloseStart: null,
      onOpenEnd: null,
      onOpenStart: null,
      outDuration: 250
    }}
    trigger={<a className=" przycisk_nawigacja z-depth-2"> <div className="white-text">Nasza Oferta</div> {' '}</a>}
  >
    <a className="black-text  " aria-label="yourdescription" href="maszyny_poziome">
    Maszyny Poziome
    </a>
   
    <a  className="black-text  " aria-label="yourdescription" href="maszyny_pionowe">
    Maszyny Pionowe
    </a>
    
    <a  className="black-text  " aria-label="yourdescription" href="maszyny_wagi">
    Wagi i urzadzenia dozujace
    </a>
    <a className="black-text" aria-label="yourdescription" href="systemy_paletyzacji">
      Systemy paletyzacji
    </a>
   
    <a className="black-text" aria-label="yourdescription" href="kartoniarka">
    Kartoniarki
    </a>
   
    <a className="black-text"aria-label="yourdescription" href="contact">
    Zaklejarki do kartonów
    </a>
    
    <a className="black-text"aria-label="yourdescription" href="contact">
    Owijarki
    </a>
   
    {/* <a className="black-text"aria-label="yourdescription" href="contact">
    Podajniki
    </a> */}
    
  </Dropdown>
{/* 
  <Dropdown
    id="Dropdown_929"
    options={{
      alignment: 'left',
      autoTrigger: true,
      closeOnClick: true,
      constrainWidth: true,
      container: null,
      coverTrigger: true,
      hover: false,
      inDuration: 150,
      onCloseEnd: null,
      onCloseStart: null,
      onOpenEnd: null,
      onOpenStart: null,
      outDuration: 250
    }}
    trigger={<a className=" przycisk_nawigacja z-depth-2"><div className="white-text">Paletyzacja</div> {' '}</a>}
  >
   
  </Dropdown> */}
{/* 
  <Dropdown
    id="Dropdown_9911"
    options={{
      alignment: 'left',
      autoTrigger: true,
      closeOnClick: true,
      constrainWidth: true,
      container: null,
      coverTrigger: true,
      hover: false,
      inDuration: 150,
      onCloseEnd: null,
      onCloseStart: null,
      onOpenEnd: null,
      onOpenStart: null,
      outDuration: 250
    }}
    trigger={<a className=" przycisk_nawigacja z-depth-2"><div className="white-text">Realizacje</div> {' '}</a>}
  >
    <a className="black-text" aria-label="yourdescription" href="naszerealizacje">
    Nasze Realiazacje
    </a>
   
    <a className="black-text" aria-label="yourdescription" href="nasiklienci">
    Nasi Klienci
    </a>
  </Dropdown> */}

  <Dropdown
    id="Dropdown_991"
    options={{
      alignment: 'left',
      autoTrigger: true,
      closeOnClick: true,
      constrainWidth: true,
      container: null,
      coverTrigger: true,
      hover: false,
      inDuration: 150,
      onCloseEnd: null,
      onCloseStart: null,
      onOpenEnd: null,
      onOpenStart: null,
      outDuration: 250
    }}
    trigger={<a className=" przycisk_nawigacja z-depth-2"> <div className="white-text">Nasza Firma</div> {' '}</a>}
  >
    <a className="black-text" aria-label="yourdescription" href="about">
    O Nas
    </a>
    
   
    <a className="black-text" aria-label="yourdescription" href="kontrahenci">
    Kontrahenci
    </a>
   
{/*     
    <a className="black-text" aria-label="yourdescription" href="certyfikaty">
    Certyfikaty
    </a> */}
   
    <a className="black-text" aria-label="yourdescription" href="kariera">
    Kariera
    </a>
  </Dropdown>
   

    <NavItem className=" przycisk_nawigacja z-depth-2" aria-label="yourdescription" href="serwis">
   <div className="white-text">Serwis</div> 
     
    </NavItem>
  <NavItem className=" przycisk_nawigacja z-depth-2" aria-label="yourdescription" href="contact">
   <div className="white-text">Kontakt</div> 
     
    </NavItem>

    <NavItem/>
{/* <Button onClick={(e) => this.handleClickPl(e)} className="przycisk_nawigacja_pl z-depth-2"></Button>
<Button  onClick={(e) => this.handleClickEn(e)} className="przycisk_nawigacja_en z-depth-2"></Button> */}
</Navbar>
)
}}
export default Nawigacja;