import {
  NavItem,
  Navbar,
  Icon,
  Button,
  Col,
  CardTitle,
  Card,
  Row,
  CardPanel,
  MediaBox,
  Divider,
  Modal,
  DatePicker,
  Form,
} from "react-materialize";

import "materialize-css";
import React, { Component } from "react";
import ListaWzor from "./ListaWzor";

class Kariera extends Component {
  constructor() {
    super();

    this.state = {
      search: null,
    };
  }

  searchSpace = (event) => {
    let keyword = event.target.value;
    this.setState({ search: keyword });
  };

  render() {
    const items = ListaWzor.filter((data) => {
      if (this.state.search == null) return data;
      else if (
        data.plname.toLowerCase().includes(this.state.search.toLowerCase()) ||
        data.plprodukty
          .toLowerCase()
          .includes(this.state.search.toLowerCase()) ||
        data.plopakowania
          .toLowerCase()
          .includes(this.state.search.toLowerCase()) ||
        data.rodzaj.toLowerCase().includes(this.state.search.toLowerCase())
      ) {
        return data;
      }
    }).map((data) => {
      return (
        <div>
          <Button className="btnwyszukiwarka orange hoverable">
            <h5>
              {data.plname}, {data.rodzaj}
            </h5>
            <p1>{data.plprodukty}</p1>
            <p1>{data.plopakowania}</p1>
          </Button>
        </div>
      );
    });

    return (
      <div>
        <Row>
          <Col m={10} s={12} push="m1">
            <Col m={12} xl={6}>
              <Card className="z-depth-2">
                <Row>
                  <Col m={6} s={12}>
                    <img
                      src="https://images.unsplash.com/photo-1581091212991-8891c7d4bd9b?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
                      alt="loading"
                      width="100%"
                    />
                  </Col>
                  <Col m={6} s={12}>
                    <h5>Informacje</h5>
                    <p align="justify">
                      Misją działu HR jest zapewnienie zarządom i pracownikom
                      Madej Pak najwyższej jakości usług w zakresie zarządzania
                      zasobami ludzkimi. Poszukujemy osób o zróżnicowanych
                      kwalifikacjach – zarówno humanistów, ekonomistów,
                      inżynierów, jak i pracowników na niższe szczeble
                      organizacji. Stawiamy przede wszystkim na ludzi
                      zaangażowanych, niebojących się wyzwań, o rozwiniętych
                      zdolnościach interpersonalnych, potrafiących odnaleźć się
                      w sytuacjach wymagających szybkiej reakcji i kreatywnego
                      rozwiązywania problemów. Z uwagi na międzynarodowy
                      charakter naszej działalności znajomość języków obcych
                      jest kwestią oczywistą. Jeśli stawiasz na rozwój, chcesz
                      pracować w międzynarodowej firmie, a wymagania są dla
                      Ciebie wyzwaniem, czekamy na Twoją aplikację! Zachęcamy
                      również do zapoznania się z aktualnymi pozycjami w
                      działach oferty pracy oraz oferty praktyk.{" "}
                    </p>
                    <br />
                    <br />
                    <div className="center">
                      <a
                        className="btn-large orange "
                        aria-label="yourdescription"
                        href="tel:+48123454397"
                      >
                        +48123454397
                      </a>
                      <br />
                      <br />

                      <a
                        className="btn-large orange "
                        aria-label="yourdescription"
                        href="mailto:+biuro@madejpak.pl"
                      >
                        biuro@madejpak.pl
                      </a>
                    </div>
                  </Col>
                </Row>
              </Card>{" "}
            </Col>
            <Col xl={1} s={0} m={0}></Col>
            <Col xl={5} s={12} m={12}>
              <Col m={12} s={12}>
                <Card className="orange klienci z-depth-3">
                  <div className="center ">
                    <h5 className="white-text">Otwarte Pozycje</h5>
                  </div>
                  <Divider />
                </Card>
              </Col>
              <Col m={12}>
                <Card
                  className="klienci z-depth-3"
                  horizontal
                  actions={[]}
                  closeIcon={<Icon>close</Icon>}
                  //   header={
                  //     <CardTitle
                  //       className="valign-center"
                  //       image="https://static.thenounproject.com/png/192296-200.png"
                  //     />
                  //   }
                  //   revealIcon={<Icon>more_vert</Icon>}
                >
                  <Row>
                    <div className="valign-wrapper">
                      <Col s={4}>
                        <img
                          src="https://static.thenounproject.com/png/192296-200.png"
                          width="100%"
                        />
                      </Col>
                      <Col s={8}>
                        <h5>Mechanik</h5>
                        <p1>Opis stanowiska: prosimy o kontakt</p1>
                        <h5>Wymagania</h5>
                        informacja dostępna po uprzednim kontakcie
                      </Col>
                    </div>
                  </Row>
                </Card>
              </Col>

              <Col m={12}>
                <Card
                  className="klienci z-depth-3"
                  horizontal
                  actions={[]}
                  closeIcon={<Icon>close</Icon>}
                >
                  <Row>
                    <div className="valign-wrapper">
                      <Col s={4}>
                        <img
                          src="https://static.thenounproject.com/png/192296-200.png"
                          width="100%"
                        />
                      </Col>
                      <Col s={8}>
                        <h5>Elektronik</h5>
                        <p1>Opis stanowiska: prosimy o kontakt</p1>
                        <h5>Wymagania</h5>
                        Doświadczneie w branży, informacja dostępna po uprzednim
                        kontakcie
                      </Col>
                    </div>
                  </Row>
                </Card>
              </Col>
            </Col>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Kariera;
