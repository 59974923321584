import {
  NavItem,
  Navbar,
  Icon,
  Button,
  Col,
  CardTitle,
  Card,
  Row,
  CardPanel,
  MediaBox,
  Divider,
  Modal,
  DatePicker,
  Form,
} from "react-materialize";
import "materialize-css";
import React, { Component } from "react";
import ListaWzor from "./ListaWzor";

class Wyszukiwarka extends Component {
  constructor() {
    super();

    this.state = {
      search: null,
      idtoshow: null,
    };
  }

  handleClick = (event) => {
    let keyid = event.target.id;
    this.setState({ idtoshow: keyid });
    console.log(keyid);
  };

  handleClickWithId = (event, id) => {
    this.setState({ idtoshow: id });
    window.scrollTo(0, 0)
  };

  searchSpace = (event) => {
    let keyword = event.target.value;
    this.setState({ search: keyword });
  };

  OnClinkSearch = (event, id) => {
    this.setState({ search: id });
  };

  render() {
    const items = ListaWzor.filter((data) => {
      if (this.state.search == null) return data;
      else if (
        data.plname.toLowerCase().includes(this.state.search.toLowerCase()) ||
        data.keyword
          .toLowerCase()
          .includes(this.state.search.toLowerCase()) ||
        data.plopakowania
          .toLowerCase()
          .includes(this.state.search.toLowerCase()) ||
        data.rodzaj.toLowerCase().includes(this.state.search.toLowerCase())
      ) {
        return data;
      }
    }).map((data) => {
      return (
        <div>
          <Button
            className="btnwyszukiwarka orange hoverable"
            onClick={(e) => this.handleClickWithId(e, data.id)}
          >
            <h5>
              {data.plname}, {data.rodzaj}
            </h5>
            
          </Button>
        </div>
      );
    });
    const pickeditem = ListaWzor.filter((data) => {
      if (this.state.idtoshow == null) return null;
      else if (data.id === this.state.idtoshow) {
        return data;
      }
    }).map((data) => {
      return (
        <div>
          <div>
            <Row>
              <Col>
                
                <Col m={12} s={12} push="">
                  <Row>
                    <Card className="klienci z-depth-3 ">
                      <Row>
                      
                        <h5>{data.plname}</h5>{data.imgglowny}
                        <Divider />
                      </Row>
                      <Row>
                        <h5>Opis:</h5>
                        <br />
                        <p className="pjustify">{data.pldescription}</p>
                      </Row>
                      <Row>
                      <br/><br/>
                        <h5>Opakowania:</h5>
                        <br />
                        
                        <img src={data.imgopakowanie1} width="20%"/>
                        <img src={data.imgopakowanie2} width="20%"/>
                        <img src={data.imgopakowanie3} width="20%"/>
                        <img src={data.imgopakowanie4} width="20%"/>
                        <img src={data.imgopakowanie5} width="20%"/>
                        <p>{data.plopakowania}</p>
                      </Row>
                      <Row>
                      <br/><br/>
                        <h5>Produkty:</h5>
                        <br />
                        <p>{data.plprodukty}</p>
                      </Row>
                      <Row><br/><br/><br/>
                      {data.daneTechnicznepl}
                      *Wydajność zależy od produktu, formatu opakowania, jakości i grubości folii oraz rodzaju systemu dozowania
                      </Row>

                      <Row>
                        <Row></Row>
                        <Divider />
                        <Row></Row>
                       <Row></Row>
                        <Row>
                          <div className="center">
                            <Button id ={data.plname} className="orange " node="button">
                              Złóż zapytanie ofertowe
                            </Button>
                          </div>
                        </Row>
                      </Row>
                    </Card>
                  </Row>
                </Col>
              </Col>
            </Row>
          </div>
        </div>
      );
    });

    return (
      <div>
        <Row>
          <Row></Row>
          <Col m={4} s={12} push="m1">
            {pickeditem}
          </Col>{/* wyszukiwarka */}
          <Col m={5} s={12} push="m2">
            <Card className="klienci">
            <h5 className="black-text">Wyszukiwarka</h5>
              <input
                className="input"
                type="text"
                placeholder="Wyszukaj maszyny po: Nazwie, rodzaju opakowan/produktow"
                onChange={(e) => this.searchSpace(e)}
              />
              </Card>
              <h5 className="center black-text">Przykładowe produkty:</h5>
              <Row>
                <Col m={2} s={2}>
              <button
                className="imgwyszukiwarka"
                onClick={(e) => this.OnClinkSearch(e, "Żywność")}
              >
                  {" "}
                  <img
                  className="imgwyszukiwarka"
                    src="https://raw.githubusercontent.com/galeriapodsloncem/3dstrona/master/babylonjs-app/public/assets/madej/food.png"
                    alt="loading"
                    width="100%"
                    
                  />
                  <Divider className=""/>
                  Żywność
              </button>
              </Col><Col m={2} s={2}>
              <button
                className="imgwyszukiwarka"
                onClick={(e) => this.OnClinkSearch(e, "Żywność")}
              >
                  {" "}
                  <img
                  className="imgwyszukiwarka"
                    src="https://raw.githubusercontent.com/galeriapodsloncem/3dstrona/master/babylonjs-app/public/assets/madej/chemicals.png"
                    alt="loading"
                    width="100%"
                  />
                  <Divider/>
                  Chemia
              </button>
              </Col><Col m={2} s={2}>
              <button
                className="imgwyszukiwarka"
                onClick={(e) => this.OnClinkSearch(e, "Żywność")}
              >
                  {" "}
                  <img
                  className="imgwyszukiwarka"
                    src="https://raw.githubusercontent.com/galeriapodsloncem/3dstrona/master/babylonjs-app/public/assets/madej/parts.png"
                    alt="loading"
                    width="100%"
                  />
                  <Divider/>
                  Części
              </button>
              </Col><Col m={2} s={2}>
              <button
                className="imgwyszukiwarka"
                onClick={(e) => this.OnClinkSearch(e, "Żywność")}
              >
                  {" "}
                  <img
                  className="imgwyszukiwarka"
                    src="https://raw.githubusercontent.com/galeriapodsloncem/3dstrona/master/babylonjs-app/public/assets/madej/coal.png"
                    alt="loading"
                    width="100%"
                  />
                  <Divider/>
                  Węgiel
              </button>
              </Col>
              </Row>
              <h5 className="center black-text">Przykładowe opakowania:</h5>
              <Row>
                <Col m={2} s={2}>
              <button
                className="imgwyszukiwarka"
                onClick={(e) => this.OnClinkSearch(e, "Żywność")}
              >
                  {" "}
                  <img
                  className="imgwyszukiwarka"
                    src="https://raw.githubusercontent.com/galeriapodsloncem/3dstrona/master/babylonjs-app/public/assets/madej/bags.png"
                    alt="loading"
                    width="100%"
                    
                  />
                  <Divider/>
                  Żywność
              </button>
              </Col><Col m={2} s={2}>
              <button
                className="imgwyszukiwarka"
                onClick={(e) => this.OnClinkSearch(e, "Żywność")}
              >
                  {" "}
                  <img
                  className="imgwyszukiwarka"
                    src="https://raw.githubusercontent.com/galeriapodsloncem/3dstrona/master/babylonjs-app/public/assets/madej/bigbag.png"
                    alt="loading"
                    width="100%"
                  />
                  <Divider/>
                  Żywność
              </button>
              </Col><Col m={2} s={2}>
              <button
                className="imgwyszukiwarka"
                onClick={(e) => this.OnClinkSearch(e, "Żywność")}
              >
                  {" "}
                  <img
                  className="imgwyszukiwarka"
                    src="https://raw.githubusercontent.com/galeriapodsloncem/3dstrona/master/babylonjs-app/public/assets/madej/pack.png"
                    alt="loading"
                    width="100%"
                  />
                  <Divider/>
                  Żywność
              </button>
              </Col><Col m={2} s={2}>
              <button
                className="imgwyszukiwarka"
                onClick={(e) => this.OnClinkSearch(e, "Żywność")}
              >
                  {" "}
                  <img
                  className="imgwyszukiwarka"
                    src="https://raw.githubusercontent.com/galeriapodsloncem/3dstrona/master/babylonjs-app/public/assets/madej/pillowbag.png"
                    alt="loading"
                    width="100%"
                  />
                  <Divider/>
                  Żywność
              </button>
              </Col>
              <Col m={2} s={2}>
              <button
                className="imgwyszukiwarka"
                onClick={(e) => this.OnClinkSearch(e, "Żywność")}
              >
                  {" "}
                  <img
                  className="imgwyszukiwarka"
                    src="https://raw.githubusercontent.com/galeriapodsloncem/3dstrona/master/babylonjs-app/public/assets/madej/plastics.png"
                    alt="loading"
                    width="100%"
                  />
                  <Divider/>
                  Żywność
              </button>
              </Col>
              <Col m={2} s={2}>
              <button
                className="imgwyszukiwarka"
                onClick={(e) => this.OnClinkSearch(e, "Żywność")}
              >
                  {" "}
                  <img
                  className="imgwyszukiwarka"
                    src="https://raw.githubusercontent.com/galeriapodsloncem/3dstrona/master/babylonjs-app/public/assets/madej/sachet.png"
                    alt="loading"
                    width="100%"
                  />
                  <Divider/>
                  Żywność
              </button>
              </Col>
              </Row>
             
              
            
            <Row>
              
            </Row>
            <h5 className="black-text">Wynik wyszukiwania (Kliknij po więcej informacji):</h5>
            
            
            {items}
          </Col>
        </Row>
      </div>
    );
  }
}

export default Wyszukiwarka;
