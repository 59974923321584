import React from "react";
import MetaTags from 'react-meta-tags';
import {
  Row,
  Card,
  Col,
  Divider,
} from "react-materialize";
import "materialize-css";
import ReactPlayer from "react-player/youtube";
const ONas = () => {
  return (
    <div>
      <Row>
        <Col m={10} s={12} offset="m1">
          <Card className="z-depth-3">
            <Row>
              <Col m={6} s={12}>
                <h3>MadejPak 20 lat doświadczenia</h3>
                <Divider />
                <p align="justify">
                  Wyobrażasz sobie swoją firmę bez zastosowań różnych nowinek
                  technologicznych? Linię produkcyjną, którą obsługuje jedna
                  osoba? W dzisiejszych czasach wszystko jest możliwe. Nasza
                  firma w całości pomoże Ci rozwinąć Twój biznes. Dzięki nam
                  zautomatyzujesz wszelkie procesy produkcyjne począwszy od
                  ważenia a skończywszy na systemie paletyzacji umożliwiającym
                  zabezpieczenie zapakowanych produktów na palecie. Madejpak to
                  idealny partner w biznesie, który pomoże Ci poprawić
                  efektywność i wydajność w Twojej firmie. Nasze maszyny do
                  pakowania oraz linie produkcyjne używane są w różnych
                  fabrykach na całym świecie. Nasi włoscy partnerzy to
                  najbardziej renomowani producenci maszyn w swojej klasie:
                  poziomych – General System Pack; pionowych – Essegi.
                  <br/>
                  Jesteśmy częścią Nadwiślańskiej Grupy Działania "E.O.CENOMA" - Gmina Drwinia
                  <a  aria-label="yourdescription"href="https://youtu.be/6WnO3808-8c">(link)</a>
                </p>
              </Col>
              <Col m={6} s={12} align="center">
              <div className='player-wrapper' align="center">
<ReactPlayer
 width='100%'
 height='100%'
 className='react-player'
url="https://youtu.be/KwhZzYt-AlE" />{" "}
</div>
               
              </Col>
            </Row>
            <Row>
              <Col m={6} s={12}>
                <br />
                <img
                  src="https://images.unsplash.com/photo-1579621970563-ebec7560ff3e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1502&q=80"
                  width="100%"
                />
              </Col>
              <Col m={6} s={12}>
                <h5>Co możesz zyskać dzięki nam?</h5>
                <br />
                <Divider />
                <p align="justify">
                Optymalizację kosztów produkcji, dynamiczne rozwijającą się
                  firmę podążającą za nowymi trendami w dziedzinie produkcji i
                  pakowania. Nowoczesne linie produkcyjne nie wymagające dużej
                  ilości pracowników. Nasz asortyment sukcesywnie się zwiększa
                  od momentu powstania firmy. Zaczynaliśmy od sprzedaży automatycznych 
                  maszyn pakujących teraz w swojej ofercie mamy nie tylko 
                  poziome i pionowe maszyny pakujące, ale takze systemy paletyzacji.
                  Przez ponad dwadzieścia lat zaopatrzyliśmy w maszyny
                  pakujące oraz linie produkcyjne setki firm. Nie ma dla nas
                  rzeczy nie możliwych do zrobienia, a do każdego klienta
                  podchodzimy indywidualnie. Potrafimy słuchać i doradzać swoim
                  klientom. Chcemy Ci zaoferować szeroki wybór naszych
                  produktów, dzięki którym wyposażysz swoją linię produkcyjną
                  niemalże od podstaw i zautomatyzujesz cały proces produkcji.
                  Oferowany przez nas sprzęt to nowoczesne maszyny pakujące takie jak:
                  poziome maszyny pakujące GSP, pionowe maszyny
                  pakujące ESSEGI, owijarki, maszyny termoformujące, wagi,
                  wszelkiego rodzaju dozowniki, kontrolery wagowe oraz całe
                  linie pakujące. Co więcej, jesteśmy również dostawcą
                  zaawansowanych systemów paletyzacji, które pozwalają na
                  automatyzację pracy w zakładzie – w skład takiego systemu
                  wchodzi układ podajników, roboty podające gotowe opakowania na
                  palecie oraz owijarki i wiązarki do palet. Systemy paletyzacji
                  dostarczane przez MadejPak cieszą się coraz większą
                  popularnością, przede wszystkim ze względu na korzyści, jakie
                  przynosi w kwestii wydajności i jakości na końcowym etapie
                  produkcji. Skontaktuj się z nami, dobierzemy dla Ciebie
                  odpowiednie maszyny do pakowania czy linie produkcyjne.
                </p>
              </Col>
            </Row>
            <Row></Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default ONas;
