import React, { useEffect } from "react";
import {

  Icon,
  Row,
  Card,
  CardTitle,
  Col,
  Slider,
  Slide,
  Divider,
  Button,
  Caption,
} from "react-materialize";
import "materialize-css";
import ikonybranze from "./ikonybranze";
import Aos from "aos";
import "aos/dist/aos.css";
// import MadejPakHome from "./icons/MadejPakHome.svg";
// import MadejPakHomeMobile from "./icons/MadejPakHomeMobile.svg";
// import Home1 from "./icons/Planta1.webp";
import Home2 from "./icons/Planta2.webp";
import Home3 from "./icons/Planta8.webp";
import Home4 from "./icons/Planta4.webp";
// import Home5 from "./icons/Planta5.webp";
// import Home6 from "./icons/Planta6.webp";
import Home7 from "./icons/Planta7.webp";
import Home8 from "./icons/Solbet3.webp";
// import Home9 from "./icons/ESAB2.webp";
import Home10 from "./icons/Agrigem_3.webp";

import IkonaMaszynyPoziome from "./icons/IkonaMaszynyPoziome.svg";
import IkonaMaszynyPionowe from "./icons/IkonaMaszynyPionowe.svg";
import IkonaPaletyzacja from "./icons/IkonaPaletyzacja.svg";
import IkonaSerwis from "./icons/IkonaSerwis.svg";
import IkonaSpozywczy from "./icons/IkonaSpozywcze.svg";
import IkonaMedyczny from "./icons/IkonaMedyczny.svg";
import IkonaBudowlany from "./icons/IkonaBudowlany.svg";
import IkonaChemiczny from "./icons/IkonaChemiczny.svg";
import IkonaInne from "./icons/IkonaInne.svg";
import MadejStoisko from "./icons/MadejStoisko.webp";
// import targi from "./icons/targi2019.webp";
// import robot from "./icons/robot.webp";
// import startowa from "./icons/madejgif.gif";
import karton from "./icons/karton.svg";
import pillow from "./icons/pillow.svg";
import pillow2 from "./icons/pillow2.svg";
import poduszkaeuro from "./icons/poduszkaeuro.svg";
import tacka from "./icons/tacka.svg";
import worek from "./icons/worek.svg";
import blockbottom from "./icons/blockbottom.svg";
import wagi from "./icons/wagi.svg";
import owijarki from "./icons/owijarki.svg";
import kartoniarki from "./icons/kartoniarki.svg";
import MetaTags from 'react-meta-tags';
const Home = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const branzeArray = ikonybranze.map((ikonybranze) => (
    <Col xl={1} l={1} m={1} s={1} push="xl1 l1">
      <Card
        className="dodatki  black-text"
        closeIcon={<Icon>close</Icon>}
        header={<CardTitle image={ikonybranze.ikona}></CardTitle>}
        revealIcon={<Icon>more_vert</Icon>}
      ></Card>
    </Col>
  ));

  return (
    <div>
      <div className="">
        <div className="homePage">
          <div className=" player-wrapper">
            <Slider
              fullscreen={true}
              options={{
                duration: 500,
                indicators: false,
                interval: 3000,
              }}
            >
              <Slide image={<img src={Home2} alt={Home2} />}>
                <Caption placement="center">
                  <Card className="sliderCard">
                    <div className="hide-on-small-only">
                      <h3 className="sliderText">
                        MadejPak - maszyny i linie pakujące{" "}
                      </h3>
                    </div>
                    <div className="hide-on-med-and-up">
                      <h5 className="sliderText">
                        MadejPak - maszyny i linie pakujące{" "}
                      </h5>
                    </div>
                  </Card>
                </Caption>
              </Slide>
              <Slide image={<img src={Home4} alt={Home4} />}>
                <Caption placement="center">
                  <Card className="sliderCard">
                    <div className="hide-on-small-only">
                      <h3 className="sliderText">
                        Współpracujemy z dużymi i małymi firmami{" "}
                      </h3>
                    </div>
                    <div className="hide-on-med-and-up">
                      <h5 className="sliderText">
                      Współpracujemy z dużymi i małymi firmami{" "}
                      </h5>
                    </div>
                  </Card>
                </Caption>
              </Slide>
              <Slide image={<img src={Home10} alt={Home10} />}>
                <Caption placement="center">
                  <Card className="sliderCard">
                  <div className="hide-on-small-only">
                      <h3 className="sliderText">
                        Branże spożywcza, medyczna, budowlana i wiele innych
                      </h3>
                    </div>
                    <div className="hide-on-med-and-up">
                      <h5 className="sliderText">
                      Branże spożywcza, medyczna, budowlana i wiele innych
                      </h5>
                    </div>
                  </Card>
                </Caption>
              </Slide>
              <Slide image={<img src={Home7} alt={Home7} />}>
                <Caption placement="center">
                  <Card className="sliderCard">
                     <div className="hide-on-small-only">
                      <h3 className="sliderText">
                        Produkcja i dystrybucja maszyn pakujących{" "}
                      </h3>
                    </div>
                    <div className="hide-on-med-and-up">
                      <h5 className="sliderText">
                        Produkcja i dystrybucja maszyn pakujących{" "}
                      </h5>
                    </div>
                  </Card>
                </Caption>
              </Slide>
              <Slide image={<img src={Home8} alt={Home8} />}>
                <Caption placement="center">
                  <Card className="sliderCard">
                    <div className="hide-on-small-only">
                      <h3 className="sliderText">
                        Robotyzacja i automatyzacja procesów produkcyjnych
                      </h3>
                    </div>
                    <div className="hide-on-med-and-up">
                      <h5 className="sliderText">
                        Robotyzacja i automatyzacja procesów produkcyjnych
                      </h5>
                    </div>
                  </Card>
                </Caption>
              </Slide>
              {/* <Slide image={<img alt={Home3} src={Home3} />}>
                <Caption placement="center ">
                  <Card className="sliderCard">
                    <div className="hide-on-small-only">
                      <h3 className="sliderText">
                        Oficjalny partner systemów paletyzacji KUKA
                      </h3>
                    </div>
                    <div className="hide-on-med-and-up">
                      <h5 className="sliderText">
                        Oficjalny partner systemów paletyzacji KUKA
                      </h5>
                    </div>
                  </Card>
                </Caption>
              </Slide> */}
            </Slider>
          </div>
        </div>

        {/* <Row>
          <div className="hide-on-med-and-up">
            <img
              className=""
              data-aos="fade-up"
              className="HomeMobile"
              src={MadejPakHomeMobile}
              width="100%"
            />
          </div>
          <div className="hide-on-small-only">
            <img
              data-aos="fade-up"
              className="MadejHome"
              src={MadejPakHome}
              width="100%"
            />
          </div>
        </Row> */}
      </div>
      <MetaTags>
      <meta name="title" content="Madejpak"/>
<meta name="description" content="Madejpak zajmuje się  projektowaniem i wykonywaniem systemów paletyzacji zaprojektowanych pod indywidualne potrzeby klienta. W Naszej ofercie posiadamy również poziome i pionowe maszyny pakujące, kartoniarki, owijarki, systemy wtaktowujące produkt czy wagi wielogłowicowe. Zapraszamy do kontaktu !"/>
<meta name="keywords" content="paletyzacja, madejpak, robot, paletyzator, system paletyzacji, podajnik, owijarka, desztapler, magazynek palet, paleta, owijarka, owijarka talerzowa, owijarka pierścieniowa, owijarka kapturowa, owijarka wolnostojąca, owijarka automatyczna, owijarka półautomatyczna, maszyna pionowa, maszyna pakująca, automatyczna pionowa maszyna pakująca, automatyczna maszyna pakująca, VFFS machine, vertical machine, FFS machine, form-fill-seal machine, Essegi, Concetti, maszyna do zamykania worków, pillow bag, block bottom, pillow bag with gussets, paper bags, opakowania, maszyna pozioma, pozioma maszyna, maszyna pakująca, automatyczna pozioma maszyna pakująca, automatyczna maszyna pakująca, FFS machine, horizontal machine, flowpack machine, flowpack, flow-pack, flowpak, form-fill-seal machine, GSP, General System Pack, Concetti, maszyna do pakowania, pillow bag, pillow bag with gussets, pillow bach z wgniotami bocznymi, opakowanie z atmosferze modyfikowanej, atmosfera modyfikowana, opakowanie, wagi, dozowniki, wagi wielogłowicowe, wagi kombinowane, wagi elektryczne, wagi taśmowe, dozownik objętościowy, dozownik kubełkowy, podajnik grawitacyjny, podajnik łańcuchowy, podajnik harmonijkowy"/>
<meta name="robots" content="index, follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="revisit-after" content="2 days"/>
<meta name="author" content="Karolina Madej"/>
</MetaTags>
      <div data-aos="slide-right" className="">
        <Row className="">
          <Col l={8} m={10} s={12} push="m1 l2">
            <Card className="cardsHome z-depth-3">
              <Row>
                <Col m={4} s={12} push="m1">
                  <img
                    className="homeImgs"
                    src={MadejStoisko}
                    alt="loading"
                    width="100%"
                  />
                </Col>
                <Col m={5} s={12} push="m2">
                  <h5>
                    Madej Pak, ponad 20 lat doświadczenia
                    <Divider />
                  </h5>
                  <p align="justify">
                  Madej Pak to polski producent i dystrybutor maszyn. Od momentu
                  powstania firmy oferujemy naszym klientom niezawodne i coraz
                  bardziej zaawansowane technicznie maszyny oraz systemy
                  pakujące. Jesteśmy w stanie kompleksowo obsługiwać naszych
                  klientów, dostarczając im kompletnie wyposażone linie
                  produkcyjne. Nasza pasja do maszyn w połączeniu z wieloletnim
                  doświadczeniem gwarantuje Państwu najwyższą jakość usług.
                  Nasza firma kompleksowo pomoże Ci rozwinąć Twój biznes. Dzięki
                  nam zautomatyzujesz wszelkie procesy produkcyjne począwszy od
                  ważenia, a skończywszy na w pełni automatycznym systemie
                  paletyzacji umożliwiającym zabezpieczenie zapakowanych
                  produktów na palecie. MADEJPAK to idealny partner w biznesie,
                  który pomoże Ci poprawić efektywność i wydajność produkcji w
                  Twojej firmie.</p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      <br />
      <br />
      <br />
      <div data-aos="slide-left" className="homePage2">
        <Row className="">
          <Col l={8} m={10} s={12} push="m1 l2">
            <Card className="cardsHome z-depth-3">
              <Row>
                <Col m={5} s={12} push="m1">
                  <h3>
                    Czym się zajmujemy?
                    <Divider />
                  </h3>
                  <p align="justify">
                  Jesteśmy w stanie kompleksowo obsługiwać naszych klientów,
                  dostarczając im kompletnie wyposażone linie produkcyjne. Nasza
                  pasja do maszyn w połączeniu z 20-letnim doświadczeniem
                  gwarantuje Państwu najwyższą jakość usług. Z naszą pomocą
                  zautomatyzują Państwo wszelkie procesy produkcyjne począwszy
                  od ważenia, a skończywszy na w pełni automatycznym systemie
                  paletyzacji umożliwiającym zabezpieczenie zapakowanych
                  produktów na palecie. MADEJPAK to idealny partner w biznesie,
                  który pomoże poprawić efektywność i wydajność produkcji w
                  Państwa firmie.</p>
                  <br />
                </Col>
                <Col m={5} s={12} push="m2">
                  <Row>
                    <Col s={6} m={4}>
                      {" "}
                      <img src={IkonaMaszynyPoziome} width="90%" />
                    </Col>
                    <Col s={6} m={4}>
                      {" "}
                      <img src={IkonaMaszynyPionowe} width="90%" />
                    </Col>
                    <Col s={6} m={4}>
                      {" "}
                      <img src={IkonaPaletyzacja} width="90%" />
                    </Col>

                    <Col s={6} m={4}>
                      {" "}
                      <img src={kartoniarki} width="90%" />
                    </Col>
                    <Col s={6} m={4}>
                      {" "}
                      <img src={owijarki} width="90%" />
                    </Col>
                    <Col s={6} m={4}>
                      {" "}
                      <img src={wagi} width="90%" />
                    </Col>
                    <Col s={6} m={4}>
                      {" "}
                      <img src={IkonaSerwis} width="80%" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      <br />
      <br />
      <br />
      <div data-aos="slide-right" className="homePage2">
        <Row className="">
          <Col l={8} m={10} s={12} push="m1 l2">
            <Card className="cardsHome z-depth-3 ">
              <Row>
                <Col m={5} s={12} push="m1" className="hide-on-small-only">
                  <Row>
                    <Col s={12} m={3}>
                      {" "}
                      <img src={IkonaSpozywczy} width="90%" />
                    </Col>
                    <Col s={12} m={8}>
                      <h5>Przemysł Spożywczy</h5>
                      np. wyroby cukiernicze, piekarnicze, mrożonki, świeże
                      warzywa i owoce
                    </Col>
                  </Row>
                  <Row>
                    <Col s={12} m={3}>
                      {" "}
                      <img src={IkonaMedyczny} width="90%" />
                    </Col>
                    <Col s={12} m={8}>
                      <h5>Przemysł Medyczny</h5>
                      np. suplementy diety
                    </Col>
                  </Row>
                  <Row>
                    <Col s={12} m={3}>
                      {" "}
                      <img src={IkonaBudowlany} width="90%" />
                    </Col>
                    <Col s={12} m={8}>
                      <h5>Branża Budowlana</h5>
                      np. materiały budowlane, piasek, żwir, kamień, produkty
                      sypkie, perlit, cement
                    </Col>
                  </Row>
                  <Row>
                    <Col s={12} m={3}>
                      {" "}
                      <img src={IkonaChemiczny} width="90%" />
                    </Col>
                    <Col s={12} m={8}>
                      <h5>Branża Chemiczna</h5>
                      np. nawozy, granulaty
                    </Col>
                  </Row>
                  <Row>
                    <Col s={12} m={3}>
                      {" "}
                      <img src={IkonaInne} width="90%" />
                    </Col>
                    <Col s={12} m={8}>
                      <h5>I Inne</h5>
                      np. nasiona trawy, kora, ziemia, ekogroszek, pellet,
                      węgiel drzewny, żwirek dla kotów, karma dla zwierząt
                    </Col>
                  </Row>
                </Col>
                <Col m={5} s={12} push="m2">
                  <h3 >
                    Jakie branże obsługujemy?
                    <Divider />
                  </h3>
                  <p className="pjustify">
                  Obsługujemy szeroką gamę branż przemysłu. Chętnie podejmujemy
                  się współpracy z partnerami z nowych branż. Dzięki naszemu
                  doświadczeniu możemy zaoferować sprawdzone i efektywne
                  rozwiazania dla każdej branży.
                  </p>
                  <Row></Row>
                  <br />
                </Col>
                <Col m={5} s={12} push="m1" className="hide-on-med-and-up">
                  <Row>
                    <Col s={2} m={3}>
                      {" "}
                      <img src={IkonaSpozywczy} width="90%" />
                    </Col>
                    <Col s={10} m={8}>
                      <h5>Przemysł Spożywczy</h5>
                      np. wyroby cukiernicze, piekarnicze, mrożonki, świeże
                      warzywa i owoce
                    </Col>
                  </Row>
                  <Row>
                    <Col s={2} m={8}>
                      {" "}
                      <img src={IkonaMedyczny} width="90%" />
                    </Col>
                    <Col s={10} m={8}>
                      <h5>Przemysł Medyczny</h5>
                      np. suplementy diety
                    </Col>
                  </Row>
                  <Row>
                    <Col s={2} m={3}>
                      {" "}
                      <img src={IkonaBudowlany} width="90%" />
                    </Col>
                    <Col s={10} m={8}>
                      <h5>Branża Budowlana</h5>
                      np. materiały budowlane, ziemia, żwir, kamień, propdukty
                      sypkie
                    </Col>
                  </Row>
                  <Row>
                    <Col s={2} m={3}>
                      {" "}
                      <img src={IkonaChemiczny} width="90%" />
                    </Col>
                    <Col s={10} m={8}>
                      <h5>Branża Chemiczna</h5>
                      np. nawozy, granulaty
                    </Col>
                  </Row>
                  <Row>
                    <Col s={2} m={3}>
                      {" "}
                      <img src={IkonaInne} width="90%" />
                    </Col>
                    <Col s={10} m={8}>
                      <h5>I Inne</h5>
                      np. nasiona trawy, ekogroszek, pellet, węgiel drzewny,
                      ogrodnictwo, kora, ziemia, karma dla zwierząt, żwirek dla
                      zwierząt,
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      <br />
      <br />
      <br />
      <div data-aos="slide-right" className="homePage2">
        <Row className="">
          <Col l={8} m={10} s={12} push="m1 l2">
            <Card className="cardsHome z-depth-3 ">
              <Row>
                <Col m={5} s={12} push="m1" className="hide-on-small-only">
                <Row className="center">
                    <Col s={6} m={4}>
                      {" "}
                      <img src={pillow2} width="90%" />
                      <br />
                      <h5>Poduszka</h5>
                    </Col>
                    <Col s={6} m={4}>
                      {" "}
                      <img src={poduszkaeuro} width="90%" />
                      <br />
                      <h5>Poduszka z euro wieszakiem</h5>
                    </Col>
                    <Col s={6} m={4}>
                      {" "}
                      <img src={pillow} width="90%" />
                      <br />
                      <h5>Poduszka z wgniotami bocznymi</h5>
                    </Col>         
                    <Col s={6} m={4}>
                      {" "}
                      <img src={tacka} width="90%" />
                      <br />
                      <h5>Tacka</h5>
                    </Col>
                    <Col s={6} m={4}>
                      {" "}
                      <img src={worek} width="90%" />
                      <br />
                      <h5>Big-bag</h5>
                    </Col>
                    <Col s={6} m={4}>
                      {" "}
                      <img src={karton} width="90%" />
                      <br />
                      <h5>Karton</h5>
                    </Col>
                    <Col s={6} m={4}>
                      {" "}
                      <img src={blockbottom} width="90%" />
                      <br />
                      <h5>Block bottom</h5>
                    </Col>
                  
                  </Row>
                </Col>
                <Col m={5} s={12} push="m2">
                  <h3>
                    Rodzaje opakowań
                    <Divider />
                  </h3>
                  <p align="justify">
                  Dzięki naszej ekspertyzie jesteśmy w stanie wybrać optymalną
                  maszynę w zależności od opakowania Państwa produktu,
                  wyselekcjonowanego pod kątem jakości, przeznaczenia oraz ceny.
                  Nasze maszyny obsługują takie opakowania jak np. worki gotowe,
                  worki z rękawa, worki z rolki folii, workie ze spłaszczonym
                  dnem, bele
                  </p>
                  <Row></Row>
                  <br />
                </Col>
                <Col m={5} s={12} push="m1" className="hide-on-med-and-up">
                <Row className="center">
                    <Col s={6} m={4}>
                      {" "}
                      <img src={pillow2} width="90%" />
                      <br />
                      <h5>Poduszka</h5>
                    </Col>
                    <Col s={6} m={4}>
                      {" "}
                      <img src={poduszkaeuro} width="90%" />
                      <br />
                      <h5>Poduszka z euro wieszakiem</h5>
                    </Col>
                    <Col s={6} m={4}>
                      {" "}
                      <img src={pillow} width="90%" />
                      <br />
                      <h5>Poduszka z wgniotami bocznymi</h5>
                    </Col>         
                    <Col s={6} m={4}>
                      {" "}
                      <img src={tacka} width="90%" />
                      <br />
                      <h5>Tacka</h5>
                    </Col>
                    <Col s={6} m={4}>
                      {" "}
                      <img src={worek} width="90%" />
                      <br />
                      <h5>Big-bag</h5>
                    </Col>
                    <Col s={6} m={4}>
                      {" "}
                      <img src={karton} width="90%" />
                      <br />
                      <h5>Karton</h5>
                    </Col>
                    <Col s={6} m={4}>
                      {" "}
                      <img src={blockbottom} width="90%" />
                      <br />
                      <h5>Block bottom</h5>
                    </Col>
                  
                  </Row> </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>

      <br />
      <br />
      <br />
      <div data-aos="slide-left" className="homePage2">
        <Row className="">
          <Col l={8} m={10} s={12} push="m1 l2">
            <Card className="cardsHome z-depth-3">
              <Row>
                <Col m={4} s={12} push="m1">
                  <img
                    className="homeImgs"
                    src="https://images.unsplash.com/photo-1512428559087-560fa5ceab42?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"
                    alt="loading"
                    width="100%"
                  />
                </Col>
                <Col m={5} s={12} push="m2">
                  <h5>
                    Jak się z nami skontaktować?
                    <Divider />
                  </h5>
                  <p align="justify">
                  W razie zainteresowania lub w celu uzyskania dodatkowych
                  informacji prosimy o kontakt za pomocą formularza
                  zgłoszeniowego lub z naszym biurem od poniedziałku do piątku,
                  w godzinach od 8:00 do 16:00. Nasi doradcy przedstawią
                  specjalnie przygotowaną ofertę oraz odpowiedzą na wszystkie
                  Państwa pytania.
                  </p>
                  <Row></Row>
                  <br />
                  <Button
                    className="z-depth-1 orange hoverable"
                    large
                    target="blank"
                    node="a"
                    href="https://www.google.com/maps/place/PHU+Madej-Pak+s.c./@50.0790093,20.4400488,15z/data=!4m5!3m4!1s0x0:0x63f37e03c8726c6f!8m2!3d50.0790093!4d20.4400488"
                  >
                    32-708 Dziewin 333
                  </Button>
                  <br /> <br />
                  <a
                    className="btn-large z-depth-1 orange hoverable "
                    href="tel:+48123454397"
                  >
                    tel:+48123454397
                  </a>{" "}
                  <br />
                  <br />
                  <a
                    className="btn-large z-depth-1 orange hoverable "
                    href="mailto:biuro@madejpak.pl"
                  >
                    biuro@madejpak.pl
                  </a>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>

      {/*       
      <div data-aos="slide-right">
        <Row className="">
        <Col m={2} s={12} push="m1">
        <br/><br/>
          <img src="https://images.unsplash.com/photo-1520031559723-e38a5ab03c79?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" alt="loading" width="100%"/>
          </Col>
          <Col m={2} s={12} push="m1">
            <h3 className="orange-text text-darken-1">Madej Pak</h3>
            <h5 className="orange-text text-darken-1">Zaufaj naszemu doświadczeniu</h5>
            <p1>
            Nasza
                  firma pomoże Ci rozwinąć Twój biznes. Dzięki nam
                  zautomatyzujesz wszelkie procesy produkcyjne począwszy od
                  ważenia a skończywszy na systemie paletyzacji umożliwiającym
                  zabezpieczenie zapakowanych produktów na palecie. Madejpak to
                  idealny partner w biznesie, który pomoże Ci poprawić
                  efektywność i wydajność w Twojej firmie. Nasze maszyny do
                  pakowania oraz linie produkcyjne używane są w różnych
                  fabrykach na całym świecie. Nasi włoscy partnerzy to
                  najbardziej renomowani producenci maszyn w swojej klasie:
                  poziomych – General System Pack; pionowych – Essegi.
            </p1>
          </Col>
          <Col m={4} s={12} push="m2">
            <h5>Prosty proces inwestycyjny</h5>
            <h5>Diagram do pokazania procesu</h5>
            zrozumienie potrzeb klienta -> znalezienie najlepszych rozwiazan w najlepszej cenie -> zamowienie ->
            realizacja -> wsparcie klienta
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <div data-aos="slide-left">
        <Card>
          <Row className="">
          <Col l={8} m={10} s={12} push="m1 l2">
              <h3 className="center">Branże z którymi współpracujemy <Divider/></h3>
              <div className="center" >
                Nasze maszyny używane są w przedsiębiorstwach z wielu sektorów; z kadym dniem powiększamy bazę przedsiębiorstw, które nam zaufały.
       
              </div>
            </Col>
            <Col l={8} m={10} s={12} push="m1 l2">
              <Carousel
                carouselId="Carousel-2"
                images={[
                  "https://cdn.iconscout.com/icon/free/png-512/drugs-26-129384.png",
                  "https://cdn.iconscout.com/icon/free/png-256/fast-food-1851561-1569286.png",
                  "https://cdn.iconscout.com/icon/free/png-256/alcohol-26-76644.png",
                  "https://img.icons8.com/emoji/452/cut-of-meat-emoji.png",
                  "https://www.flaticon.com/svg/static/icons/svg/1265/1265584.svg",
                  "https://www.shareicon.net/data/512x512/2016/06/01/773909_man_512x512.png",
                  "https://image.flaticon.com/icons/png/512/28/28387.png",
                  "https://www.pngrepo.com/download/189137/paint-bucket-bucket.png",
                  "https://cdn.onlinewebfonts.com/svg/img_415179.png",
                  "https://img.icons8.com/plasticine/2x/cake.png",
                  "https://cdn.iconscout.com/icon/free/png-512/loaf-bread-1508808-1275257.png",
                  "https://www.pngrepo.com/download/274788/coal.png",
                  "https://image.flaticon.com/icons/png/512/1656/1656569.png",
                  "https://www.pngrepo.com/download/278752/window-cleaner-detergent.png",
                ]}
                options={{
                  dist: -100,
                  duration: 200,
                  fullWidth: false,
                  indicators: true,
                  noWrap: false,
                  numVisible: 14,
                  onCycleTo: null,
                  padding: 0,
                  shift: 0,
                }}
              />
            </Col>
            
          </Row>
        </Card>
      </div>
      <div data-aos="slide-right">
        <Row className="">
          <Col l={8} m={10} s={12} push="m1 l2">
            <h3 className="center white-text">Przykładowe opakowania<Divider/></h3>
            <p1>
              
            </p1>
          </Col>
          <Col l={8} m={10} s={12} push="m1 l2">
            <Carousel
              carouselId="Carousel-21"
              images={[
                "https://raw.githubusercontent.com/galeriapodsloncem/3dstrona/master/babylonjs-app/public/assets/madej/bags.png",
                "https://raw.githubusercontent.com/galeriapodsloncem/3dstrona/master/babylonjs-app/public/assets/madej/bigbag.png",
                "https://raw.githubusercontent.com/galeriapodsloncem/3dstrona/master/babylonjs-app/public/assets/madej/pack.png",
                "https://raw.githubusercontent.com/galeriapodsloncem/3dstrona/master/babylonjs-app/public/assets/madej/pillowbag.png",
                "https://raw.githubusercontent.com/galeriapodsloncem/3dstrona/master/babylonjs-app/public/assets/madej/plastics.png",
                "https://raw.githubusercontent.com/galeriapodsloncem/3dstrona/master/babylonjs-app/public/assets/madej/sachet.png",
              ]}
              options={{
                dist: -100,
                duration: 200,
                fullWidth: false,
                indicators: true,
                noWrap: false,
                numVisible: 14,
                onCycleTo: null,
                padding: 0,
                shift: 0,
              }}
            />
          </Col>
        </Row>
      </div>
      <div data-aos="slide-left">
        <Card>
          <Row className="">
            <Col className="center"   m={4} s={12} push="m1">
              <br />
              <img
              
                src="https://images.unsplash.com/photo-1553678324-f84674bd7b24?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2134&q=80"
                width="80%"
              />
              <br />
              <br />
            </Col>
            <Col className="" m={5} s={12} push="m2">
              <h3>
                Nowoczesne i efektywne <br />
                rozwiązania dla Twojej firmy
                <Divider/>
              </h3>
              <h5 className="left-align">
                <li>Wysokowydajne</li>
                <li>Niezawodne</li>
                <li>Precyzyjne</li>
                <li>Elastyczne</li>
                <li>Proste w obsłudze
</li>
                <li>Nowoczesne</li>
                <li>Wysoka wydajność</li>
                <li>Wzrost efektywności</li>
                <li>Wysoka jakość produkcji</li>
                <li>Komunikacja z innymi systemami</li>

                </h5>
              
            </Col>
          </Row>
        </Card>
      </div>

      <div data-aos="slide-right">
        <Row className="">
          <Col className="center " m={6} s={12} push="m1">
            <h3>Indywidualne Realizacje</h3>
            <p1>
              opis opisopis opisopis opisopis opisopis opisopis opisopis
              opisopis opisopis opisopis opis
            </p1>
          </Col>
          <Col m={4} s={12} push="m1">
            <img
              src="https://images.unsplash.com/photo-1578574577315-3fbeb0cecdc2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1504&q=80"
              width="100%"
            />
          </Col>
        </Row>
      </div>*/}
    </div>
  );
};
export default Home;
