import React from "react";
import {
  Icon,
  Row,
  Card,
  CardTitle,
  Col,
  Divider,
  Carousel,
} from "react-materialize";
import "materialize-css";
// import robot from "./icons/robot6b.png";
import Iframe from "react-iframe";
import test from "../components/zdjecia/test.avif";
import targi2022 from "../components/zdjecia/targi2022.jpeg";
import targi2023 from "../components/zdjecia/targi2023.jpg";
import targi2019 from "../components/zdjecia/BAKEPOL2019.png";
import targi2023_1 from "../components/zdjecia/targi_automatyki2023.jpeg";
import dniotwarte2023 from "../components/zdjecia/DNIOTWARTE2023.png";
import ReactPlayer from "react-player/youtube";
import MetaTags from "react-meta-tags";
import dniotwarte2024 from "../components/zdjecia/dniotwarte2024.svg";
import otwarte24 from "../components/zdjecia/5.jpg"

const ONas = () => {
  return (
    <div>
      <Row>
      <Col m={10} s={12} offset="m1">
       {/* <img className="z-depth-3 homeImgs" src={otwarte24} width="100%"/>*/}
          { <Card className="z-depth-3 homeImgs">
  
            <Row>
              <h1 className="center">
                DNI OTWARTE MADEJPAK 2024 <Divider />
              </h1>
              <h4 className="center">Dziewin, 22.05.2024</h4>

              <Col m={10} s={12} push="m1">
                <Divider />
             
                <p align="justify">
                  <h5>
                  <br/><br/>
                    Zapraszamy serdecznie na DNI OTWARTE MADEJPAK,
                    które odbędą się 22 maja 2024r. <br/>w naszej
                    siedzibie w Dziewinie. 
                    </h5>
<h5>
                    <br/><br/>
                    <b>Adres wydarzenia</b> 
                    <br/><br/>Dziewin 333, 32-708 Dziewin 
                    
                    <a target="blank" href="https://maps.app.goo.gl/yYNkJiBhwWrYXxcX8"> Link do map Google</a>
                    </h5>
                    <h5>
                    <br/><br/>
                    <b>Program wydarzenia</b> 
                    <br/><br/>·Informacje techniczne <br/><br/>·Pokazy na żywo <br/><br/> ·Q&A <br/><br/>
                     
                  </h5>
                </p>
                <br />
                <img src={dniotwarte2024} width="100%" />
              </Col>
            </Row>
          
          
          </Card>
       }
        </Col>

        <Col m={10} s={12} offset="m1">
          <Card className="z-depth-3 homeImgs">
            <Row>
              <h1 className="center">
                DNI OTWARTE MADEJPAK 2023 <Divider />
              </h1>
              <h5 className="center">Dziewin, 15 - 16.11.2023</h5>

              <Col m={10} s={12} push="m1">
                <Divider />
                {/* <p align="justify">
                  W tym roku jesteśmy na targach spożywczych z poziomymi
                  maszynami pakującymi włsokiej firmy GSP – General System Pack;
                  pionowych idealnymi do pracy z produktami z branży spożywczej.
                </p><br/> 
                <Divider />
                <h4>
                  Automatyzacja i robotyzacja procesów pakowania z Madejpak
                </h4>
                <br />
                <Divider />*/}
                <p align="justify">
                  <h5>
                    Mamy przyjemność zaprosić Państwa na DNI OTWARTE MADEJPAK,
                    które odbędą się w dniach 15 i 16 listopada 2023r. w
                    siedzibie MADEJPAK w Dziewinie. 
                    <br/><br/>
                    Adres wydarzenia: Dziewin
                    333, 32-708 Dziewin 
                    <br/><br/>
                    Wskazówki jak do nas dojechać:
                    <a target="blank" href="https://maps.app.goo.gl/yYNkJiBhwWrYXxcX8"> Link do map Google</a>
                    
                    <br/><br/>
                    W programie: 
                    <br/>·Prezentacja najnowszych produktów i instalacji MADEJPAK <br/>·Pokazy pracy maszyn oraz zrobotyzowanego stanowiska opartego
                    na robocie KUKA <br/>·Rozmowy i dyskusje z ekspertami z branży
                    automatyki i robotyzacji procesów pakowania
                     <br/>·Przerwa kawowa i lunch <br/>·Dla uczestników specjalne ceny dnia, możliwość
                    uzyskania dodatkowych rabatów. Dołącz do grona pasjonatów
                    robotyzacji i automatyzacji procesów pakowania. <br/><br/>Osoby
                    zainteresowane prosimy o potwierdzenie przybycia.
                  </h5>
                </p>
                <br />
                <img src={dniotwarte2023} width="100%" />
              </Col>
            </Row>
          </Card>
        </Col>

        
        <Col m={10} s={12} offset="m1">
          <Card className="z-depth-3 homeImgs">
            <Row>
              <h1 className="center">
                Targi: Warsaw Industry Automatica 2023 <Divider />
              </h1>
              <h5 className="center">Warszawa, 09-11.05 2023</h5>
              <h3 className="center">Stoisko nr B3.01, Hala B</h3>
              <Col m={10} s={12} push="m1">
                <h3>Targi automatyki i robotyki przemysłowej</h3>
                <Divider />
                {/* <p align="justify">
                  W tym roku jesteśmy na targach spożywczych z poziomymi
                  maszynami pakującymi włsokiej firmy GSP – General System Pack;
                  pionowych idealnymi do pracy z produktami z branży spożywczej.
                </p><br/> */}
                <Divider />
                <h4>
                  Automatyzacja i robotyzacja procesów pakowania z Madejpak
                </h4>
                <br />
                <Divider />
                <p align="justify">
                  <h5>
                    Oferujemy szeroki wybór produktów, dzięki którym możliwe
                    jest wyposażenie firm niemalże od podstaw, aż po{" "}
                    <a
                      className="blue-text"
                      aria-label="yourdescription"
                      href="systemy_paletyzacji"
                    >
                      {" "}
                      systemy paletyzacji{" "}
                    </a>
                    umożliwiające przygotowanie towaru na paletach do
                    transportu.
                  </h5>
                </p>{" "}
                <br />
                <p align="justify">
                  <h5>
                    Z Madejpak automatyzujesz cały proces produkcji. Oferowany
                    przez nas sprzęt to nowoczesne maszyny pakujące takie jak:{" "}
                    <a
                      className="blue-text"
                      target="_blank"
                      aria-label="yourdescription"
                      href="maszyny_poziome"
                    >
                      poziome maszyny pakujące{" "}
                    </a>{" "}
                    <a node="a" target="_blank" href="https:/www.gsp.it/en/">
                      {" "}
                      General System Pack
                    </a>
                    ,
                    <a
                      className="blue-text"
                      target="_blank"
                      aria-label="yourdescription"
                      href="maszyny_pionowe"
                    >
                      pionowe maszyny pakujące{" "}
                    </a>
                    <a
                      node="a"
                      target="_blank"
                      href="https:/www.essegi.com/en/"
                    >
                      {" "}
                      Essegi
                    </a>
                    ; owijarki, maszyny termoformujące,{" "}
                    <a
                      className="blue-text"
                      target="_blank"
                      aria-label="yourdescription"
                      href="maszyny_wagi"
                    >
                      wagi wielogłowicowe
                    </a>
                    , wszelkiego rodzaju dozowniki, kontrolery wagowe oraz całe
                    linie pakujące. Co więcej, jesteśmy również producentem i
                    dostawcą{" "}
                    <a
                      className="blue-text"
                      aria-label="yourdescription"
                      href="systemy_paletyzacji"
                    >
                      zaawansowanych systemów paletyzacji,{" "}
                    </a>{" "}
                    które pozwalają na automatyzację pracy w zakładzie – w skład
                    takiego systemu wchodzi układ podajników, roboty podające
                    gotowe opakowania na paletę oraz owijarki. W niektórych
                    przypadkach wykorzystuje się także wiązarki do palet.
                  </h5>
                </p>{" "}
                <br />
                <p align="justify">
                  <h5>
                    <span>
                      <a
                        className="blue-text"
                        aria-label="yourdescription"
                        href="systemy_paletyzacji"
                      >
                        Systemy paletyzacji
                      </a>
                    </span>{" "}
                    dostarczane przez MadejPak cieszą się coraz większą
                    popularnością, przede wszystkim ze względu na korzyści,
                    jakie przynosi w kwestii wydajności i jakości na końcowym
                    etapie produkcji.
                  </h5>
                </p>
                <br />
                <p align="justify">
                  <h5>
                    Zapraszamy na Targi Automatyki i Robotyki do Warszawy !
                  </h5>
                </p>{" "}
                <br />
                <p align="justify">
                  <h5>
                    Na naszym stoisku B3.01 na hali B będzie mozna zobaczyć
                    poglądową paletyzację Madejpak opartą na robocie
                    <a
                      node="a"
                      target="_blank"
                      href="https://www.kuka.com/pl-pl"
                    >
                      {" "}
                      KUKA
                    </a>
                    ,
                    <a
                      className="blue-text"
                      target="_blank"
                      aria-label="yourdescription"
                      href="maszyny_pionowe"
                    >
                      pionową maszynę pakującą{" "}
                    </a>
                    <a
                      node="a"
                      target="_blank"
                      href="https:/www.essegi.com/en/"
                    >
                      {" "}
                      Essegi
                    </a>{" "}
                    i{" "}
                    <a
                      className="blue-text"
                      target="_blank"
                      aria-label="yourdescription"
                      href="maszyny_poziome"
                    >
                      poziomą maszynę pakującą{" "}
                    </a>
                    <a node="a" target="_blank" href="https:/www.gsp.it/en/">
                      {" "}
                      General System Pack
                    </a>
                    .{/*Więcej informacji: www.bakepol.pl */}
                  </h5>
                </p>{" "}
                <br />
                <img src={targi2023_1} width="100%" />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col m={10} s={12} offset="m1">
          <Card className="z-depth-3 homeImgs">
            <Row>
              <h1 className="center">
                Targi: Warsaw Pack 2023 <Divider />
              </h1>
              <h5 className="center">Warszawa, 18-20.04 2023</h5>
              <h3 className="center">Stoisko nr B3.01, Hala B</h3>
              <Col m={10} s={12} push="m1">
                <h3>MadejPak ponad 20 lat doświadczenia</h3>
                <Divider />
                <h4>Co możesz zyskać dzięki nam?</h4>
                <br />
                <Divider />
                <p align="justify">
                  <h5>
                    Optymalizację kosztów produkcji, dynamiczne rozwijającą się
                    firmę podążającą za nowymi trendami w dziedzinie produkcji i
                    pakowania. Nowoczesne linie produkcyjne nie wymagające dużej
                    ilości pracowników. Nasz asortyment sukcesywnie się zwiększa
                    od momentu powstania firmy. Zaczynaliśmy od sprzedaży{" "}
                    <a
                      className="blue-text"
                      target="_blank"
                      aria-label="yourdescription"
                      href="maszyny_poziome"
                    >
                      automatycznych poziomych maszyn pakujących{" "}
                    </a>{" "}
                    teraz w swojej ofercie mamy nie tylko poziome i{" "}
                    <a
                      className="blue-text"
                      target="_blank"
                      aria-label="yourdescription"
                      href="maszyny_pionowe"
                    >
                      pionowe maszyny pakujące,{" "}
                    </a>
                    ale także{" "}
                    <a
                      className="blue-text"
                      aria-label="yourdescription"
                      href="systemy_paletyzacji"
                    >
                      {" "}
                      systemy paletyzacji{" "}
                    </a>
                    .
                  </h5>
                </p>
                <br />
                <p align="justify">
                  <h5>
                    Przez ponad dwadzieścia lat zaopatrzyliśmy w maszyny
                    pakujące, kompletne linie pakujące oraz{" "}
                    <a
                      className="blue-text"
                      aria-label="yourdescription"
                      href="systemy_paletyzacji"
                    >
                      {" "}
                      systemy paletyzacji{" "}
                    </a>
                    setki firm. Nie ma dla nas rzeczy nie możliwych do
                    zrobienia, a do każdego klienta podchodzimy indywidualnie.
                    Potrafimy słuchać i doradzać swoim klientom.
                  </h5>
                </p>
                <br />
                <p align="justify">
                  <h5>
                    Skontaktuj się z nami, dobierzemy dla Ciebie odpowiednie
                    maszyny pakujące, system paletyzacji lub zaprojektujemy i
                    wykonamy innowacyjne jedyne w swoim rodzaju urządzenie.
                  </h5>
                </p>
                <br />
                <p align="justify">
                  <h5>
                    Zapraszamy do odwiedzenia naszego stoiska nr B3.01 na hali B
                    !
                  </h5>
                </p>{" "}
                <br />
                {/*  */}
                <img src={targi2023} width="100%" />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col m={10} s={12} offset="m1">
          <Card className="z-depth-3 homeImgs">
            <Row>
              <h1 className="center">
                Targi: FoodTech Expo 2022 <Divider />
              </h1>
              <h5 className="center">Warszawa, 29.11-01.12 2022</h5>
              <h3 className="center">Stoisko nr B1.07, Hala B</h3>
              <Col m={10} s={12} push="m1">
                <h3>Automatyzacja pakowania w branży spożywczej</h3>

                <Divider />
                {/* <p align="justify">
                  W tym roku jesteśmy na targach spożywczych z poziomymi
                  maszynami pakującymi włsokiej firmy GSP – General System Pack;
                  pionowych idealnymi do pracy z produktami z branży spożywczej.
                  Wyobrażasz sobie swoją firmę bez zastosowań różnych nowinek
                  technologicznych? Linię produkcyjną, którą obsługuje jedna
                  osoba?
                </p><br/> */}
                {/* <Divider />
                
                <h4>Wyobrażasz sobie swoją firmę wyposażoną w linię produkcyjną, którą obsługuje jedna osoba?</h4>
                <br />

                <Divider /> */}

                <p align="justify">
                  <h5>
                    W dzisiejszych czasach wszystko jest możliwe. Nasza firma w
                    całości pomoże Ci rozwinąć Twój biznes. Dzięki nam
                    zautomatyzujesz wszelkie procesy produkcyjne począwszy od
                    ważenia a skończywszy na{" "}
                    <a
                      className="blue-text"
                      aria-label="yourdescription"
                      href="systemy_paletyzacji"
                    >
                      {" "}
                      systemie paletyzacji Madejpak
                    </a>{" "}
                    umożliwiającym zabezpieczenie zapakowanych produktów na
                    palecie.
                  </h5>
                </p>
                <br />

                <p align="justify">
                  <h5>
                    Madejpak to idealny partner w biznesie, który pomoże Ci
                    poprawić efektywność i wydajność w Twojej firmie. Nasze
                    maszyny pakujące oraz{" "}
                    <a
                      className="blue-text"
                      aria-label="yourdescription"
                      href="systemy_paletyzacji"
                    >
                      {" "}
                      systemy paletyzacji Madejpak
                    </a>{" "}
                    są używane w różnych fabrykach na całym całego kraju. Nasi
                    włoscy partnerzy to najbardziej renomowani producenci maszyn
                    w swojej klasie: poziomych –{" "}
                    <a node="a" target="_blank" href="https:/www.gsp.it/en/">
                      General System Pack
                    </a>
                    ; pionowych –{" "}
                    <a
                      node="a"
                      target="_blank"
                      href="https:/www.essegi.com/en/"
                    >
                      Essegi
                    </a>
                    ; linii pakujących -{" "}
                    <a node="a" target="_blank" href="https:/www.concetti.com">
                      Concetti
                    </a>
                    ;
                  </h5>
                </p>
                <br />
                {/*  */}
                <img src={targi2022} width="100%" />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col m={10} s={12} offset="m1">
          <Card className="z-depth-3 homeImgs">
            <Row>
              <h2 className="center">
                Targi: TaroPak 2021 <Divider />
              </h2>
              <h5 className="center">Poznań, 04-06 / 10 / 2021</h5>
              <h3 className="center">Stoisko nr 76, pawilon 6</h3>
              <Col m={10} s={12} push="m1">
                <Divider />

                <Row>
                  <Col s={12} m={10} push="m1">
                    <div className="player-wrapper" align="center">
                      <ReactPlayer
                        width="100%"
                        height="100%"
                        className="react-player"
                        url="https://youtu.be/ZdDocwCBgd0"
                      />{" "}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col m={10} s={12} offset="m1">
          <Card className="z-depth-3 homeImgs">
            <Row>
              <h1 className="center">
                Targi: Bakepol 2019 <Divider />
              </h1>
              <h5 className="center">Kraków, 12-14.09 2019</h5>
              {/* <h3 className="center">Stoisko nr B1.07, Hala B</h3> */}
              <Col m={10} s={12} push="m1">
                <h3>Automatyczne poziome maszyny pakujące</h3>
                <Divider />
                {/* <p align="justify">
                  W tym roku jesteśmy na targach spożywczych z poziomymi
                  maszynami pakującymi włsokiej firmy GSP – General System Pack;
                  pionowych idealnymi do pracy z produktami z branży spożywczej.
                </p><br/> */}
                <p align="justify">
                  <h5>
                    Targi Przemysłu Piekarskiego i Cukierniczego BAKEPOL
                    zadebiutują w nowej lokalizacji – siódma edycja wydarzenia
                    odbędzie się w Krakowie. Targi BAKEPOL to nie tyko wystawa
                    maszyn i urządzeń do produkcji, to także ekspozycja
                    automatycznych maszyn pakujących dla przemysłu spożywczego,
                    cukierniczego, piekarniczego oraz WIELU innych gałęzi
                    przemysłu.
                  </h5>
                </p>
                <br />
                <p align="justify">
                  <h5>
                    Na naszym stoisku będzie można obejrzeć{" "}
                    <a
                      className="blue-text"
                      target="_blank"
                      aria-label="yourdescription"
                      href="maszyny_poziome"
                    >
                      poziome maszyny pakujące{" "}
                    </a>{" "}
                    włoskiej firmy{" "}
                    <a node="a" target="_blank" href="https:/www.gsp.it/en/">
                      General System Pack
                    </a>
                    , które idealnie sprawdzają się w małych i dużych
                    przedsiębiorstwach na całym świecie do pakowania produktów
                    piekarniczych, cukierniczych, spożywczych i wielu innych.
                  </h5>
                </p>
                <br />
                <p align="justify">
                  <h5>
                    Zapraszamy na Targi BAKEPOL 12-14.09.2019r. do Krakowa
                    {/*Więcej informacji: www.bakepol.pl */}
                  </h5>
                </p>{" "}
                <br />
                <img src={targi2019} width="100%" />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      {/* 
            <Row>

              <Col m={12} s={12}>

                <p>
                  <div>
                    <Row>
                      <Col m={6} s={12} push="m3">
                        <Card className=" z-depth-3 klienci orange">
                          <div className="center">
                            <div className="white-text ">
                              <h4>Nasi Kontrahenci</h4>
                              <Divider />
                              <br></br>

                            </div>
                          </div>
                        </Card>



                        <Card
                          className="kliencicard z-depth-3"
                          actions={[
                            <a className="center" key="1"  aria-label="yourdescription"href="https://www.concetti.com/">
                              website
              </a>,
                          ]}
                          closeIcon={<Icon>close</Icon>}
                          horizontal
                          revealIcon={<Icon>more_vert</Icon>}
                        >
                          <div className="center">
                            <img src="https://bulkinside.com/wp-content/uploads/2011/12/Concetti.png" alt="loading" width="30%" />
                          </div>
                          <br />
            CONCETTI zapewnia w pełni gotowe systemy, zgodnie ze szczególnymi
            wymaganiami klienta. Oferowane systemy zostały zaprojektowane
            specjalnie dla różnorodnych firm , które wymagają maksymalnej
            elastyczności w obsłudze różnych produktów przy minimalnym czasie
            przeznaczonym na zmianę formatu. Podczas integracji całego systemu,
            wszystkie maszyny są w pełni samo-regulujące. Concetti oferuje
            systemy dozowania, maszyny formujące, napełniające i zamykające
            opakowanie, maszyny do napełniania i zamykania prefabrykowanych
            opakowań, systemy napełniania BIG-BAG, w pełni automatyczne
            paletyzery oraz wiele innych w pełni spersonalizowanych rozwiązań.
          </Card>


                        <Card
                          className="kliencicard z-depth-3"
                          actions={[
                            <a className="center" key="1"  aria-label="yourdescription"href="https://www.essegi.com/en/">
                              website
              </a>,
                          ]}
                          closeIcon={<Icon>close</Icon>}

                          horizontal
                          revealIcon={<Icon>more_vert</Icon>}
                        >
                          <div className="center">
                            <img src="https://www.essegi.com/wp-content/uploads/2019/08/logo-350.png" alt="loading" width="30%" />
                          </div>
                          <br />
            ESSEGI jest wiodącą firmą w dziedzinie projektowania i budowania
            pionowych systemów pakowania, pras do belowania, linii mieszających
            do gleby, systemów ważących i automatyki przemysłowej dla różnych
            produktów, obejmujących szeroki zakres wag i formatów. Ponad
            trzydzieści lat doświadczenia pozwoliło ESSEGI na produkcję
            zaawansowanych technologicznie maszyn, a także na budowę wysoko
            wykwalifikowanej i pomocnej sieci sprzedaży, której częścią jest
            firma MADEJPAK- autoryzowany i wyłączny przedstawiciel ESSEGI w
            Polsce.
          </Card>


                        <Card
                          className="kliencicard z-depth-3"
                          actions={[
                            <a className="center" key="21"  aria-label="yourdescription"href="https://www.gsp.it/en/">
                              website
              </a>,

                          ]}
                          closeIcon={<Icon>close</Icon>}

                          horizontal
                          revealIcon={<Icon>more_vert</Icon>}

                        >
                          <div className="center">
                            <img src="https://www.gsp.it/public/img/pagine/20171212_121144_51S91T.png" alt="loading" width="30%" />
                          </div>
            General System Pack to włoska firma, która projektuje, buduje i
            dostarcza maszyny pakujące oraz automatyczne systemy pakowania od
            prawie trzydziestu lat. Wspólnie wyposażyliśmy w innowacyjne systemy
            i maszyny pakujące setki firm w całym kraju, przyczyniając się tym
            do ich prężnego rozwoju . Możemy poszczycić się długą listą
            zadowolonych klientów. Nasi klienci to firmy działające praktycznie
            we wszystkich możliwych gałęziach przemysłu.
          </Card>



                        {/* <Card
                          className="kliencicard z-depth-3"
                          actions={[
                            <a className="center" key="1"  aria-label="yourdescription"href="https://www.tosagroup.com/en/">
                              website
              </a>,
                          ]}
                          closeIcon={<Icon>close</Icon>}
                          horizontal
                        >
                          <div className="center">
                            <img src="https://www.tosagroup.com/wp-content/uploads/2017/10/logo-tosa.png" alt="loading" width="30%" />
                          </div>
            TOSA jest włoską grupą, należącą do liderów na międzynarodowym
rynku urządzeń „END-OF-LINE”.
Dzięki trzem markom TOSA, MIMI i CMR jesteśmy w stanie zaspokoić
każdą potrzebę stabilizacji i bezpieczeństwa ładunków, niezależnie od tego,
czy są na paletach, czy nie, dzięki naszej pełnej gamie maszyn do owijania,
maszyn do wiązania (paskowania) i obkurczania.
          </Card> 
                      </Col>


                    </Row>
                  </div>
                </p>
              </Col>

            </Row> */}
    </div>
  );
};
export default ONas;
