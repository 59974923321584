import React from "react";
import YouTube from "react-youtube";
import {
  Table,
  MediaBox,
  Icon,
  Row,
  CardPanel,

  Col,
 
  Toast,
} from "react-materialize";
import "materialize-css";
import Divider from "react-materialize/lib/Divider";
import "react-leaflet";
import Button from "react-materialize/lib/Button";
import emailjs from "@emailjs/browser";
import Iframe from 'react-iframe'
import ReactGA from "react-ga";
import useAnalyticsEventTracker from './useAnalyticsEventTracker';
function sendEmail(e) {
  e.preventDefault();

  emailjs
    .sendForm(
      "service_aso22qi",
      "template_ppuo1ll",
      e.target,
      {
        publicKey: 'IAq3TNyMDLA3SxC62'},
    )
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
}

const Contact = () => {
  
  const gaEventTracker = useAnalyticsEventTracker('Contact us');
    return (
      <div>
        <Row>
          <Col m={4} s={12} offset="m1">
            <div className="contactButtoms hide-on-med-and-up">
              <Button
                className="z-depth-2 orange "
                target="blank"
                node="a"
                 aria-label="maps"href="https://www.google.com/maps/place/PHU+Madej-Pak+s.c./@50.0790093,20.4400488,15z/data=!4m5!3m4!1s0x0:0x63f37e03c8726c6f!8m2!3d50.0790093!4d20.4400488"
              >
                Google Maps
              </Button>
              <br/>
              <a onClick={()=>gaEventTracker('call')} className="btn orange "  aria-label="yourdescription"href="tel:+48123454397">
                Zadzwoń
              </a>
              <br/>
              <a onClick={()=>gaEventTracker('email')} className="btn orange "  aria-label="yourdescription"href="mailto:biuro@madejpak.pl">
                e-mail
              </a>
            </div>

            <CardPanel className="white z-depth-2">
              <h5 className="center">Formularz Kontaktowy</h5>
              <Row></Row>
              <Divider />
              <Row></Row>
              <div className="center ">
                <Row>
                <form className="contact-form" onSubmit={sendEmail}>
                  <input type="text" name="from_name" />
                  <label>Imię i nazwisko</label>
                  <input type="text" name="firma" />
                  <label>Firma</label>
                  <input type="email" name="user_email" /> <label>Email</label>
                  <input type="text" name="produkt" />
                  <label>Państwa produkt</label>
                  <input type="text" name="message" />
                  <label>
                    Wiadomość, np. zapytanie o rodzaj maszyny dla konkretnego
                    produktu
                  </label>
      <div className="center">
                  <Toast
                    className="white center z-depth-0"
                    
                    options={{
                      classes: "center",
                      html: "Wysłano wiadomość.",
                    }}
                  >
                    <input
                      className="btn-large orange white-text"
                      type="submit"
                      value="wyślij"
                    />
                  </Toast>
              </div>
                </form>
                </Row>
              </div>
            </CardPanel>
            <div className="contactButtoms hide-on-small-only ">
              <Button
                className="z-depth-2 orange "
                large
                target="blank"
                node="a"
                 aria-label="yourdescription"href="https://www.google.com/maps/place/PHU+Madej-Pak+s.c./@50.0790093,20.4400488,15z/data=!4m5!3m4!1s0x0:0x63f37e03c8726c6f!8m2!3d50.0790093!4d20.4400488"
              >
                Google Maps
              </Button>
              <a className="btn-large orange "  aria-label="yourdescription"href="tel:+48123454397">
                Zadzwoń
              </a>
              <a className="btn-large orange "  aria-label="yourdescription"href="mailto:biuro@madejpak.pl">
                e-mail
              </a>
            </div>

            <CardPanel className="white z-depth-2"><p align="justify">
              W razie zainteresowania lub w celu uzyskania dodatkowych
              informacji prosimy o kontakt za pomocą formularza zgłoszeniowego
              lub z naszym biurem od poniedziałku do piątku, w godzinach od 8:00
              do 16:00. Nasi doradcy przedstawią specjalnie przygotowaną ofertę
              oraz odpowiedzą na wszystkie Państwa pytania.</p>
              <Row></Row>
              <br />
              PPHU Madejpak sp. z o.o.
              <br />
              Dziewin 333
              <br />
              32-708 Dziewin
              <br />T : +48 12 345 43 97
              <br />
              E: biuro@madejpak.pl
            </CardPanel>
          </Col>

          <Col m={1} s={0} />
          <Col m={5} s={12}>
            <Row></Row>
            <Row></Row>
            <Row></Row>
           
            <Iframe
                  url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1900.478244255241!2d20.438449109723315!3d50.079019926625655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165b9c11cb7c65%3A0x63f37e03c8726c6f!2sPHU%20Madej-Pak%20s.c.!5e0!3m2!1spl!2spl!4v1655396134506!5m2!1spl!2spl"
                  width="100%"
                  height="600px"
                  id="myId"
                  className="myClassname"
                  display="initial"
                  position="relative"
                />{" "}

          </Col>
        </Row>
      </div>
    );
  }

export default Contact;
