import React, { Component, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Nawigacja from "./components/Nawigacja";
import "materialize-css";
import Home from "./components/Home";
import Contact from "./components/Contact";
import MaszynyPionowe from "./components/MaszynyPionowe";
import MaszynyWagi from "./components/MaszynyWagi";
import MaszynyPaletyzacja from "./components/MaszynyPaletyzacja";
import MaszynyKartoniarki from "./components/MaszynyKartoniarki";
import MaszynyPoziome from "./components/MaszynyPoziome";
import MaszynyOwijarki from "./components/MaszynyOwijarki";
import Wyszukiwarka from "./components/Wyszukiwarka";
import Kariera from "./components/Kariera";
import Serwis from "./components/Serwis";
import Targi from "./components/Targi";
import { Footer, Modal, Button } from "react-materialize";
import Certyfikaty from "./components/Certyfikaty";
import NaszeRealizacje from "./components/NaszeRealizacje";
import NasiKlienci from "./components/NasiKlienci";
import ONas from "./components/ONas";
import Kontrahenci from "./components/Kontrahenci";
import MaszynyZaklejarki from "./components/MaszynyZaklejarki";
import MaszynyPodajniki from "./components/MaszynyPodajniki";
import rzetelna from "./components/zdjecia/rzetelna.svg"
import Emerito from "./components/Emerito.jsx"
import ReactGA from 'react-ga';
import Wszystkie from './components/WszystkieMaszyny';
import MetaTags from 'react-meta-tags';

  const TRACKING_ID = "G-SXXHPR7C8E"; 
  
  ReactGA.initialize(TRACKING_ID);
const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
 
    document.title = "MadejPak";
    document.getElementsByTagName("META")[2].content =
      "MadejPak maszyny pakujace,linie pakujace, paletyzacja";
      
    return (
      <BrowserRouter>
        <div className="App">
          <Nawigacja />
          {/* <div className="hide-on-large-only">
             <NawigacjaTelefon/>
           </div> */}
          <div className="row" />
          <div className="row">
            <div className="col s12 m12 l12 xl12">
              <Switch>
                {/* ONas = klienci */}
                <Route path="/about" component={ONas} />
                <Route path="/Home" component={Home} />
                <Route path="/contact" component={Contact} />
                <Route path="/Maszyny" component={Wszystkie} />
                <Route path="/Maszyny_poziome" component={MaszynyPoziome} />
                <Route path="/Maszyny_pionowe" component={MaszynyPionowe} />
                <Route path="/Wagi" component={MaszynyWagi} />
                <Route
                  path="/systemy_paletyzacji"
                  component={MaszynyPaletyzacja}
                />
                <Route path="/kartoniarka" component={MaszynyKartoniarki} />
                <Route path="/owijarki" component={MaszynyOwijarki} />
                <Route path="/zaklejarki" component={MaszynyZaklejarki} />
                <Route path="/podajniki" component={MaszynyPodajniki} />
                <Route path="/wyszukiwarka" component={Wyszukiwarka} />
                <Route path="/kariera" component={Kariera} />
                <Route path="/serwis" component={Serwis} />
                <Route path="/naszerealizacje" component={NaszeRealizacje} />
                <Route path="/nasiklienci" component={NasiKlienci} />
                <Route path="/certyfikaty" component={Certyfikaty} />
                <Route path="/maszyny_wagi" component={MaszynyWagi} />
                <Route path="/kontrahenci" component={Kontrahenci} />
                <Route path="/emerito" component={Emerito} />
                <Route path="/targi" component={Targi} />
                <Route path="/wyszukiwarka" component={Wyszukiwarka} />
                <Route path="/:any" component={Home} />
                <Route path="/" component={Home} />
              </Switch>
            </div>
          </div>
          <div className="">
            <Footer
              className="  orange darken-1"
              copyrights=" 2030 Copyright wszelkie prawa zastrzeżone. Wykorzystywanie materiałów zamieszczonych na tej stronie wymaga pisemnego pozwolenia od firmy PPHU MADEJPAK SP. Z O.O."
              links={
                <div>
                  <Modal
                    actions={[
                      <Button
                        className=""
                        flat
                        modal="close"
                        node="button"
                        waves="green"
                      >
                        Close
                      </Button>,
                    ]}
                    bottomSheet={false}
                    fixedFooter={false}
                    header="  Klauzula informacyjna dla klientów i kontrahentów.  "
                    id="Modal-vv0"
                    open={false}
                    options={{
                      dismissible: true,
                      endingTop: "10%",
                      inDuration: 250,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      opacity: 0.5,
                      outDuration: 250,
                      preventScrolling: true,
                      startingTop: "4%",
                    }}
                    trigger={
                      <Button className="grey darken-1" large node="button">
                        RODO
                      </Button>
                    }
                  >
                    <br />
                    Prywatność
                    <br />
                    Na tej stronie opisano sposób zarządzania witryną w
                    odniesieniu do przetwarzania danych osobowych użytkowników, którzy z niej korzystają. Jest to
                    informacja przekazywana również na podstawie i dla celów art. 13 Rozporządzenia
                    Europejskiego 679/2016 - Ogólne rozporządzenie o ochronie danych (RODO) dla osób, które
                    wchodzą w interakcję z oficjalną stroną internetową firmy PPHU MADEJPAK SP. Z O.O. ,
                    dostępną drogą elektroniczną pod adresem www.madejpak.pl
                    <br />
                    Informacje są dostarczane tylko dla strony internetowej www.madejpak.pl, a nie dla innych stron internetowych, z którymi użytkownik może się zapoznać za
                    pośrednictwem linków.
                    <br />
                    Gromadzenie danych
                    <br />
                    Po przejrzeniu tej strony mogą być przetwarzane dane dotyczące zidentyfikowanych lub możliwych do zidentyfikowania osób. Właścicielem tych danych
                    jest firma PPHU MADEJPAK SP. Z O.O.
                    <br />
                    Miejsce przetwarzania danych
                    <br />
                    Przetwarzanie danych osobowych związanych z usługami internetowymi tej witryny odbywa się w wyżej wymienionym biurze i jest obsługiwane wyłącznie
                    przez upoważniony personel (technik odpowiedzialny za przetwarzanie) lub osoby odpowiedzialne za okazjonalne czynności konserwacyjne.
                    <br />
                    Żadne dane pochodzące z serwisu internetowego nie są przekazywane ani rozpowszechniane,z wyjątkiem anonimowej i zbiorczej formy.
                    <br />
                    Dane osobowe przekazywane przez użytkowników, którzy przesyłają zapytania lub komunikaty (e-mail, wiadomości, prośby itp.) są
                    wykorzystywane wyłącznie w celu wykonania żądanej usługi lub świadczenia i są ujawniane
                    osobom trzecim tylko wtedy, gdy jest to przeznaczone do tego celu i jest to niezbędne do
                    wykonania żądanej usługi lub świadczenia.
                    <br />
                    <br />
                    Rodzaje przetwarzanych danych
                    <br />
                    Systemy komputerowe i procedury oprogramowania wykorzystywane do obsługi tej strony internetowej podczas normalnego działania uzyskują pewne
                    dane osobowe, których transmisja jest nieodłączna w korzystaniu z protokołów
                    komunikacji internetowej. Informacje te nie są gromadzone w celu powiązania ze zidentyfikowanymi
                    zainteresowanymi stronami, ale które ze względu na swój charakter mogłyby, poprzez
                    przetwarzanie i powiązanie z danymi posiadanymi przez strony trzecie, umożliwić
                    identyfikację użytkowników.
                    <br />
                    Ta kategoria danych obejmuje na przykład adresy IP
                    komputerów, z których korzystają użytkownicy, którzy łączą się z witryną, adresy internetowe
                    żądanych zasobów, czas żądania, sposób przesłania żądania do serwera, rozmiar pliku
                    uzyskanego w odpowiedzi oraz inne parametry związane z systemem operacyjnym i środowiskiem
                    informatycznym użytkownika.
                    <br />
                    Dane te są wykorzystywane wyłącznie w celu sprawdzenia
                    poprawności działania serwisu oraz uzyskania anonimowych informacji statystycznych
                    dotyczących korzystania z serwisu.
                    <br />
                    Dane mogą być wykorzystane do ustalenia odpowiedzialności w
                    przypadku hipotetycznych przestępstw komputerowych przeciwko witrynie.
                    <br />
                    Dane podane dobrowolnie przez użytkownika
                    <br />
                    Opcjonalne, jednoznaczne i dobrowolne wysyłanie wiadomości
                    e-mail za pośrednictwem adresów i funkcji znajdujących się na tej stronie pociąga za
                    sobą późniejsze pozyskanie adresu nadawcy, niezbędnego do odpowiedzi na żądania, a
                    także wszelkich innych danych osobowych zawartych w wiadomości.
                    <br />
                    Opcjonalne dostarczenie danych
                    <br />
                    Oprócz danych określonych dla danych nawigacyjnych,
                    użytkownik może podać dane osobowe w celu zażądania przesłania materiałów
                    informacyjnych lub innej komunikacji; brak ich podania może uniemożliwić uzyskanie tego, o co proszono.
                    <br />W trosce o kompletność należy pamiętać, że w niektórych przypadkach (niepodlegających zwykłemu zarządzaniu tą stroną) organ ochrony danych
                    osobowych może zażądać wiadomości i informacji od osób zarządzających stroną w celu
                    monitorowania przetwarzania danych osobowych. W takich przypadkach odpowiedź jest
                    obowiązkowa pod groźbą sankcji administracyjnej.
                    <br />
                    <br />
                    Metody przetwarzania
                    <br />
                    Dane osobowe są przetwarzane za pomocą zautomatyzowanych
                    narzędzi przez czas ściśle niezbędny do osiągnięcia celów, dla których zostały zebrane.
                    Podejmowane są szczególne środki bezpieczeństwa, aby zapobiec utracie danych,
                    nielegalnemu lub nieprawidłowemu użyciu oraz nieautoryzowanemu dostępowi.
                    <br />
                    Twoje dane osobowe mogą być udostępniane podmiotom trzecim w
                    zależności od rodzaju wybranych i autoryzowanych przez Ciebie usług, usług
                    dodatkowych oraz przekazywania informacji handlowych.
                    <br />
                    Twoje dane mogą być również udostępnione np. następującym
                    podmiotom:
                    <br />
                    konsultanci, księgowi lub inne wyspecjalizowane firmy do
                    celów księgowych i podatkowych;
                    <br />
                    instytucje finansowe, instytucje bankowe i ubezpieczeniowe;
                    <br />
                    podmioty przetwarzające dane w celu wykonania określonych
                    obowiązków prawnych;
                    <br />
                    organom sądowym lub administracyjnym, w celu wypełnienia
                    obowiązków prawnych.
                    <br />
                    Lista zewnętrznych menedżerów dostępna jest u administratora
                    danych.
                    <br />
                    Zatrzymywanie danych
                    <br />
                    Na podstawie art. 5 ust. 1 lit. e) Rozporządzenia RODO,
                    przetwarzane dane osobowe będą przechowywane w postaci umożliwiającej identyfikację osoby,
                    której dane dotyczą, przez okres nie dłuższy niż niezbędny do celów, dla których
                    zostały zebrane lub następnie przetwarzane.
                    <br />
                    Dane uważane za „niewrażliwe”, takie jak dane analityczne,
                    będą przechowywane przez nieograniczony czas, ponieważ pozwalają nam zidentyfikować
                    zachowanie użytkownika bez bezpośredniej identyfikacji osoby.
                    <br />
                    Prawa użytkownika i prawo do bycia zapomnianym
                    <br />
                    Podmioty, których dotyczą dane osobowe, mają prawo w
                    dowolnym momencie uzyskać potwierdzenie istnienia lub braku takich danych oraz poznać
                    ich treść i pochodzenie,zweryfikować ich poprawność lub zażądać ich integracji,
                    aktualizacji lub sprostowania (art.15-22). Rozporządzenia UE 2016/679).
                    <br />
                    <br />
                    Na podstawie tych samych artykułów przysługuje prawo żądania
                    usunięcia, przekształcenia w formę anonimową lub zablokowania danych przetwarzanych z
                    naruszeniem prawa, a także w każdym przypadku, z uzasadnionych przyczyn, sprzeciwić się
                    ich przetwarzaniu.
                    <br />
                    Żądania należy kierować do osoby odpowiedzialnej za poufność
                    danych osobowych firmy PPHU MADEJPAK SP. Z O. O., z którą można się skontaktować
                    pod adresem e-mail rodo@madejpak.pl.
                    <br />
                    Ciasteczka
                    <br />
                    Ta strona nie używa żadnego rodzaju „trwałych plików cookie”
                    ani systemów do śledzenia użytkowników.
                    <br />
                    Jakiekolwiek użycie „cookies sesyjnych” (które nie są przechowywane na stałe na komputerze użytkownika i znikają po zamknięciu przeglądarki)
                    ogranicza się do przesyłania identyfikatorów sesji (składających się z losowych liczb
                    generowanych przez serwer) niezbędnych do umożliwienia bezpiecznej i sprawnej
                    eksploracji witryny. Stosowanie „cookies sesyjnych” pozwala uniknąć korzystania z innych
                    technik informatycznych, które potencjalnie zagrażają poufności nawigacji użytkowników.
                    <br />
                    Pliki cookie stron trzecich.
                    <br />
                    Nasza witryna może zawierać pliki cookie wydawane przez
                    strony trzecie (agencje reklamowe, firmy zajmujące się pomiarami oglądalności itp.),
                    które umożliwiają im, w okresie ważności tych plików cookie, zbieranie informacji z
                    terminali nawigacyjnych, które odwiedzają naszą witrynę.
                    <br />
                    Kontynuując przeglądanie tej witryny, zaznaczasz, że chcesz
                    korzystać z oferowanych produktów i usług, a tym samym wyrażasz zgodę na korzystanie
                    z plików cookie i innych technologii niezbędnych do ich przeglądania.
                    <br />
                    Uwalnianie i używanie plików cookie przez strony trzecie
                    podlega odpowiedniej polityce ochrony prywatności.
                    <br />
                    Poniżej informujemy o temacie plików cookie, o których
                    wiemy, i udostępniamy powiązane strony szczegółowe.
                  </Modal>

                  <Modal
                    actions={[
                      <Button
                        className=""
                        flat
                        modal="close"
                        node="button"
                        waves="green"
                      >
                        Close
                      </Button>,
                    ]}
                    bottomSheet={false}
                    fixedFooter={false}
                    header="  Klauzula informacyjna dla klientów i kontrahentów.  "
                    id="Modal-vvas0"
                    open={false}
                    options={{
                      dismissible: true,
                      endingTop: "10%",
                      inDuration: 250,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      opacity: 0.5,
                      outDuration: 250,
                      preventScrolling: true,
                      startingTop: "4%",
                    }}
                    trigger={
                      <Button className="orange " large node="button">
                        Legal
                      </Button>
                    }
                  >
                    <br />
                    Uwagi prawne
                    <br />
                    Informacje na tej stronie mogą zawierać błędy techniczne lub
                    typograficzne. Informacje mogą ulec zmianie lub aktualizacji
                    bez powiadomienia.
                    <br />
                    PPHU MADEJPAK SP. Z O.O. może również dokonywać ulepszeń
                    <br />i zmian w produktach opisanych na tej stronie
                    internetowej w dowolnym czasie bez powiadomienia.
                    <br />
                    PPHU MADEJPAK SP. Z O.O. nie ponosi żadnej
                    odpowiedzialności za dokładność informacji dostarczanych na
                    tej stronie internetowej i korzystanie z takich informacji
                    odbywa się na własne ryzyko odbiorcy.
                    <br />
                    PPHU MADEJPAK SP. Z O.O. nie gwarantuje, że wszelkie
                    zgłoszone problemy mogą zostać rozwiązane z wykorzystaniem
                    jakichkolwiek informacji zawartych w tej witrynie
                    internetowej.
                    <br />
                    Przekazując informacje, PPHU MADEJPAK SP. Z O.O. nie
                    udziela żadnych licencji do jakichkolwiek praw autorskich,
                    patentów ani innych praw własności intelektualnej.
                  </Modal>

                  <Modal
                    actions={[
                      <Button
                        className=""
                        flat
                        modal="close"
                        node="button"
                        waves="green"
                      >
                        Close
                      </Button>,
                    ]}
                    bottomSheet={false}
                    fixedFooter={false}
                    header="  Cookies  "
                    id="Modal-vvadass0"
                    open={false}
                    options={{
                      dismissible: true,
                      endingTop: "10%",
                      inDuration: 250,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      opacity: 0.5,
                      outDuration: 250,
                      preventScrolling: true,
                      startingTop: "4%",
                    }}
                    trigger={
                      <Button className="grey " large node="button">
                        Cookies
                      </Button>
                    }
                  >
                    <br />
                    Ta strona korzysta z ciasteczek, aby świadczyć usługi na
                    najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich
                    użycie.
                    <br />
                    Rodzaje wykorzystywanych Cookies
                    <br />
                    Stosowane przez Administratora Cookies są bezpieczne dla
                    Urządzenia Użytkownika.
                    <br />
                    W szczególności tą drogą nie jest możliwe przedostanie się
                    do Urządzeń Użytkowników wirusów lub innego niechcianego oprogramowania, lub
                    oprogramowania złośliwego. 
                    <br />
                    Pliki te pozwalają zidentyfikować oprogramowanie wykorzystywane
                    przez Użytkownika i dostosować Serwis indywidualnie każdemu Użytkownikowi.
                    <br />
                    Cookies zazwyczaj zawierają nazwę domeny, z której pochodzą, czas przechowywania ich
                    na Urządzeniu oraz przypisaną wartość.
                    <br />
                    Administrator wykorzystuje dwa typy plików cookies:
                    <br />
                    Cookies sesyjne: są przechowywane na Urządzeniu Użytkownika
                    i pozostają tam do momentu zakończenia sesji danej przeglądarki. Zapisane
                    informacje są wówczas trwale usuwane z pamięci Urządzenia. Mechanizm cookies sesyjnych
                    nie pozwala na pobieranie jakichkolwiek danych osobowych ani żadnych informacji
                    poufnych z Urządzenia Użytkownika. 
                    <br />
                    Cookies trwałe: są przechowywane na Urządzeniu Użytkownika i pozostają tam do momentu ich skasowania. Zakończenie sesji danej
                    przeglądarki lub wyłączenie Urządzenia nie powoduje ich usunięcia z Urządzenia
                    Użytkownika. Mechanizm cookies trwałych nie pozwala na pobieranie jakichkolwiek danych
                    osobowych ani żadnych informacji poufnych z Urządzenia Użytkownika. Użytkownik ma możliwość
                    ograniczenia lub  wyłączenia dostępu plików cookies do swojego Urządzenia.
                    <br />
                    W przypadku skorzystania z tej opcji korzystanie ze Serwisu będzie możliwe, poza
                    funkcjami, które ze swojej natury wymagają plików cookies.
                  
                    <br />
                    Cele, w jakich wykorzystywane są Cookies
                    <br />
                    Administrator wykorzystuje Cookies własne w celu poprawnej
                    konfiguracji serwisu, a w szczególności do: dostosowania zawartości stron
                    internetowych Serwisu do preferencji Użytkownika oraz optymalizacji korzystania ze stron
                    internetowych Serwisu; rozpoznania urządzenia Użytkownika Serwisu i odpowiednio wyświetlenia
                    strony internetowej, dostosowanej do jego indywidualnych potrzeb.
                    <br />
                    <br />
                    Dane osobowe
                    <br />
                    Możecie Państwo korzystać z naszej strony bez udostępniania
                    nam prywatnych informacji.
                    <br />
                    Jednakże, jeśli chcielibyście otrzymać szczegółowe
                    informacje na temat naszej oferty,
                    konieczne jest przesłanie nam danych osobowych
                    umożliwiających kontakt. Kładziemy duży
                   
                    nacisk na bezpieczeństwo powierzanych nam danych osobowych.
                    Stosujemy się ściśle
                 
                    do postanowień Ustawy z dnia 29.08.1997 r. o ochronie danych
                    osobowych (Dz.U. Nr 133 poz.883) oraz wytycznych prawa europejskiego, stosowanego
                    w krajach UE.
                    <br />
                    <br />
                    Łącza do stron zewnętrznych
                    <br />
                    Strona internetowa PPHU MADEJPAK SP. Z O.O.
                 może zawierać linki do innych stron, które działają niezależnie od nas i nie są
                    przez nas kontrolowane. Podajemy je wyłącznie dla wygody i informacji Użytkowników. Strony te
                    mogą mieć własne zasady ochrony danych osobowych — zachęcamy więc do zachowania
                    ostrożności przy opuszczaniu naszej witryny oraz do zapoznania się z politykami
                    prywatności w każdym innym serwisie gromadzącym dane osobowe. Nie bierzemy także
                    odpowiedzialności za informacje prezentowane na takich stronach ani za inne działania
                    prowadzone za ich pośrednictwem.
                   
                    <br />
                    Zabezpieczenia
                    
                    <br />
                    Witryna zaopatrzona jest w środki bezpieczeństwa mające
                    na celu ochronę danych pozostających pod naszą kontrolą przed utratą, niewłaściwym
                    wykorzystaniem i modyfikacją.
                    <br />
                    Dostęp do danych osobowo-adresowych ma tylko ograniczona
                    liczba uprawnionych pracowników PPHU MADEJPAK SP. Z O.O.
                    Nad dostępem do informacji czuwa zaawansowany system zarządzania
                    uprawnieniami, co w połączeniu z szyfrowaniem wybranych danych w bazie danych chroni
                    przed uzyskaniem dostępu do nich nieuprawnionym osobom.
                    <br />
                    <br />
                    Wyrażenie zgody
                    <br />
                    <br />
                    Korzystanie z witryny internetowej MadejPak jest
                    równoznaczne z wyrażeniem zgody na warunki przedstawione w niniejszej Polityce
                    Prywatności oraz na przetwarzanie przez MadejPak danych osobowych
                    podawanych przez Użytkownika do wyżej wymienionych celów lub celów
                    określonych w treści stron.
                  </Modal>

                  <Button
                    className="z-depth-2 orange darken-2 "
                    large
                    target="blank"
                    node="a"
                    aria-label="yourdescription" href="https://www.google.com/maps/place/PHU+Madej-Pak+s.c./@50.0790093,20.4400488,15z/data=!4m5!3m4!1s0x0:0x63f37e03c8726c6f!8m2!3d50.0790093!4d20.4400488"
                  >
                    Google Maps
                  </Button>

                  <a
                    className="btn-large grey  darken-2"
                    aria-label="yourdescription" href="tel:+48123454397"
                  >
                    Zadzwoń
                  </a>
                  <a
                    className="btn-large orange lighten-1 "
                    aria-label="yourdescription" href="mailto:biuro@madejpak.pl"
                  >
                    e-mail
                  </a>
                </div>
              }
              //  moreLinks={<a className="grey-text text-lighten-4 right" aria-label="yourdescription" href="#!">More Links</a>}
            >
              <h5 className="white-text">Madej Pak</h5>
              <br />
              PPHU MADEJPAK SP. Z O. O.
              <br />
              Dziewin 333
              <br />
              32-708 Dziewin
              <br />T : +48 12 345 43 97
              <br />
              E: biuro@madejpak.pl
              <hr />
              Jesteśmy uczestnikiem Programu RZETELNA Firma
              <br />
            
              <a aria-label="yourdescription" href="https://wizytowka.rzetelnafirma.pl/5OXAFNZQ">
              <img src={rzetelna} width="50%"/>

              </a>
              <br/>
              https://wizytowka.rzetelnafirma.pl/5OXAFNZQ
            </Footer>
          </div>
        </div>
      </BrowserRouter>
    );
  }


export default App;
