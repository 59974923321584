import {
    NavItem,
    Navbar,
    Icon,
    Button,
    Col,
    CardTitle,
    Card,
    Row,
    CardPanel,
    MediaBox,
    Divider,
    Modal,
    DatePicker,
    CollapsibleItem,
    Collapsible,
    Toast,
    Form,
  } from "react-materialize";
  import "materialize-css";
  import React, { Component } from "react";
  import ListaWzor from "./ListaWzor";
  import emailjs from "@emailjs/browser";
  import call from "./icons/call.jpg"
import emerito from "./zdjecia/emeritos.png"
   
   class MaszynyPionowe extends Component {
    constructor() {
      super();
  
      this.state = {
        search: "podajniki",
        idtoshow: null,
      };
    }
    sendEmail(e) {
      e.preventDefault();
  
      emailjs
        .sendForm(
          "service_aso22qi",
          "template_ppuo1ll",
          e.target,
          "user_iiHIuApii5dTFW9DZsq3i"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  
    handleClick = (event) => {
      let keyid = event.target.id;
      this.setState({ idtoshow: keyid });
      console.log(keyid);
    };
  
    handleClickWithId = (event, id) => {
      this.setState({ idtoshow: id });
      window.scrollTo(0, 0);
    };
  
    searchSpace = (event) => {
      let keyword = event.target.value;
      this.setState({ search: keyword });
    };
  
    OnClinkSearch = (event, id) => {
      this.setState({ search: id });
    };
  
    render() {
      const items = ListaWzor.filter((data) => {
        if (this.state.search == null) return data;
        else if (
          // data.plname.toLowerCase().includes(this.state.search.toLowerCase())
          // ||
          // data.keyword
          //   .toLowerCase()
          //   .includes(this.state.search.toLowerCase()) ||
          // data.plopakowania
          //   .toLowerCase()
          //   .includes(this.state.search.toLowerCase()) ||
          data.rodzaj.toLowerCase().includes(this.state.search.toLowerCase())
        ) {
          return data;
        }
      }).map((data) => {
        return (
          <div>
            <div>
              <Col m={5} s={12} push="m1">
                <Card
                  className="kartyproduktow"
                  actions={[
                    <Row>
                      <Col>
                        <Modal
                          actions={[]}
                          className="modaleProdukty"
                          bottomSheet={false}
                          fixedFooter={false}
                          id={data.plname}
                          open={false}
                          options={{
                            dismissible: true,
                            endingTop: "2%",
                            inDuration: 250,
                            onCloseEnd: null,
                            onCloseStart: null,
                            onOpenEnd: null,
                            onOpenStart: null,
                            opacity: 0.5,
                            outDuration: 250,
                            preventScrolling: true,
                            startingTop: "2%",
                          }}
                          trigger={
                            <div className="center">
                              <Button large className="orange " node="button">
                                Wiecej informacji
                              </Button>
                            </div>
                          }
                        >
                          <div>
                            <Row>
                              <Col>
                                <Col m={6} s={12}>
                                  {/* <Card className=" "> */}
                                  <Row>
                                    <img
                                      alt="loading"
                                      src={data.imgglowny}
                                      width="100%"
                                    />
                                  </Row>
                                  {/* </Card> */}
                                </Col>{" "}
                                <Col m={6} s={12} push="">
                                  <Row>
                                    {/* <Card className=""> */}
                                    <Col m={12} s={12} push="">
                                      <h5>{data.plname}</h5>
                                      <Divider />
                                    </Col>
                                    <Col m={12} s={12} push="">
                                      <h5>Opis:</h5>
                                      <br />
                                      <p align="justify">{data.pldescription}</p>
                                    </Col>
                                    <Col m={12} s={12} push="">
                                      <h5>Opakowania:</h5>
                                      <br />
                                      <p align="justify">{data.plopakowania}</p>
                                    </Col>
                                    <Col m={12} s={12} push="">
                                      <h5>Produkty:</h5>
                                      <br />
                                      <p align="justify">{data.plprodukty}</p>
                                    </Col>
                                    <Col m={12} s={12} push="">
                                      <h5>Wydajnosc:</h5>
                                      <br />
                                      <p align="justify">{data.wydajnoscpl}</p>
                                    </Col>
  
                                    <Row>
                                      <Row></Row>
                                      <Divider />
                                      <Row>
                                        <div className="center">
                                          <Modal
                                          className="white"
                                            actions={[
                                              <Button
                                                large
                                                modal="close"
                                                node="button"
                                                waves="orange"
                                                className="orange center"
                                              >
                                                Close
                                              </Button>,
                                            ]}
                                            bottomSheet={false}
                                            fixedFooter={false}
                                            header={data.plname}
                                            id={data.imgglowny}
                                            open={false}
                                            options={{
                                              dismissible: true,
                                              endingTop: "10%",
                                              inDuration: 250,
                                              onCloseEnd: null,
                                              onCloseStart: null,
                                              onOpenEnd: null,
                                              onOpenStart: null,
                                              opacity: 0.5,
                                              outDuration: 250,
                                              preventScrolling: true,
                                              startingTop: "4%",
                                            }}
                                            // root={[object HTMLBodyElement]}
                                            trigger={
                                              <Button
                                                large
                                                className="orange"
                                                node="button"
                                              >
                                                Złóż zapytanie ofertowe
                                              </Button>
                                            }
                                          >
                                            <form
                                              className="contact-form"
                                              onSubmit={this.sendEmail}
                                            >   <input
                                                type="text"
                                                name="from_name"
                                              />
                                              <label>Imię i nazwisko</label>
                                            <input type="text" name="firma" />
                                              <label>Firma</label>
                                             
                                              
                                              <input
                                                value={data.plname}
                                                type="text"
                                                name="maszyna"
                                              /><label>Maszyna</label>
                                            
                                              <input
                                                type="email"
                                                name="user_email"
                                              />{" "}  <label>Email</label>
                                          
                                              <input type="text" name="produkt" />    <label>Państwa produkt</label>
                                              
                                              <input type="text" name="message" /><label>
                                                Wiadomość, np. zapytanie o rodzaj
                                                maszyny dla konkretnego produktu
                                              </label>
                                              <div className="center">
                    <Toast
                      className="white center z-depth-0"
                      
                      options={{
                        classes: "center",
                        html: "Wysłano wiadomość.",
                      }}
                    >
                      <input
                        className="btn-large orange white-text"
                        type="submit"
                        value="wyślij"
                      />
                    </Toast>
                </div>
                                            </form>
                                          </Modal>
                                          <br />
                                          <br />
                                          <a
                                            className="btn-large orange "
                                            aria-label="yourdescription" href="tel:+48123454397"
                                          >
                                            Zadzwon
                                          </a>
                                          <Button
                                            large
                                            className="grey darken-3"
                                            modal="close"
                                            node="button"
                                            waves="green"
                                          >
                                            Zamknij
                                          </Button>
                                        </div>
                                      </Row>
                                    </Row>
                                    {/* </Card> */}
                                  </Row>
                                </Col>
                              </Col>
                            </Row>
                          </div>
                        </Modal>
                      </Col>
                      <h5 className="right">{data.plname}</h5>
                    </Row>,
                  ]}
                >
                  {data.imgglowny}
                </Card>
              </Col>
            </div>
          </div>
        );
      });
      const pickeditem = ListaWzor.filter((data) => {
        if (this.state.idtoshow == null) return null;
        else if (data.id === this.state.idtoshow) {
          return data;
        }
      }).map((data) => {
        return (
          <div>
            <div>
              <Row>
                <Col>
                  <Col m={12} s={12} push="">
                    <Row>
                      <Card className="klienci z-depth-3 ">
                        <Row>
                          <h5>{data.plname}</h5>
                          {data.imgglowny}
                          <Divider />
                        </Row>
                        <Row>
                          <h5>Opis:</h5>
                          <br />
                          <p className="pjustify">{data.pldescription}</p>
                        </Row>
                        <Row>
                          <br />
                          <br />
                          <h5>Opakowania:</h5>
                          <br />
  
                          <img src={data.imgopakowanie1} width="20%" />
                          <img src={data.imgopakowanie2} width="20%" />
                          <img src={data.imgopakowanie3} width="20%" />
                          <img src={data.imgopakowanie4} width="20%" />
                          <img src={data.imgopakowanie5} width="20%" />
                          <p>{data.plopakowania}</p>
                        </Row>
                        <Row>
                          <br />
                          <br />
                          <h5>Produkty:</h5>
                          <br />
                          <p>{data.plprodukty}</p>
                        </Row>
                        <Row>
                          <br />
                          <br />
                          <br />
                          {data.daneTechnicznepl}
                          *Wydajność zależy od produktu, formatu opakowania,
                          jakości i grubości folii oraz rodzaju systemu dozowania
                        </Row>
  
                        <Row>
                          <Row></Row>
                          <Divider />
                          <Row></Row>
                          <Row></Row>
                          <Row>
                            <div className="center">
                              <Button
                                id={data.plname}
                                className="orange "
                                node="button"
                              >
                                Złóż zapytanie ofertowe
                              </Button>
                            </div>
                          </Row>
                        </Row>
                      </Card>
                    </Row>
                  </Col>
                </Col>
              </Row>
            </div>
          </div>
        );
      });
  
      return (
        <div>
          <Row>
            <div className="">
              <Col m={2} s={12} push="m1">
                <h5>Zadzwoń lub napisz do nas, pomożemy Ci wybrać odpowiedni produkt.</h5>
              </Col>
              <Col m={1} s={12} push="m1">
                <br />
                <br />
                <br />
                <div>
                  <a className="btn-large orange " aria-label="yourdescription" href="tel:+48123454397">
                    Zadzwoń
                  </a>
                </div>
              </Col>
              <Col m={1} s={2} push="m1 s6">
                
                <img
                  className="hide-on-small-only"
                  src={call}
                  width="100%"
                />
              </Col>
            </div>
          </Row>
          <Divider />
   
          <Row><Col s={12} m={10} push="m1">
              <a aria-label="yourdescription" href="https://drive.google.com/file/d/1gtZ1BhQOY7AhRj7e305UE0rLds_n_ozq/view?usp=sharing">
          <img src={emerito} width="100%"/>
</a>
</Col></Row>



          
          {/* <Row>{items}</Row> */}
        
          {/* <Row>
            <Col m={5} s={12} push="m1">
              <Collapsible accordion={false}>
                <CollapsibleItem
                  expanded={false}
                  header="podajniki (więcej informacji)"
                  node="div"
                >
                  <div className="black-text">
                    <h5>
                      {" "}
                      podajniki
                    </h5>
                  </div>
                </CollapsibleItem>
                <CollapsibleItem
                  expanded={false}
                  header="podajniki (więcej informacji)"
                  node="div"
                >
                  <h5>
                  podajniki
                  </h5>
                </CollapsibleItem>
                <CollapsibleItem
                  expanded={false}
                  header="podajniki (więcej informacji)"
                  node="div"
                >
                  <h5>
                  podajniki
                  </h5>
                </CollapsibleItem>
              </Collapsible>
            </Col>
            <div className="hide-on-small-only">
              <Col m={2} s={12} push="m1">
                <h5>Zadzwoń do nas, pomożemy Ci wybrać odpowiedni produkt.</h5>
              </Col>
              <Col m={1} s={12} push="m1">
                <br />
                <br />
                <br />
                <div>
                  <a className="btn-large orange " aria-label="yourdescription" href="tel:+48123454397">
                    Zadzwoń
                  </a>
                </div>
              </Col>
              <Col m={1} s={12} push="m1">
                <img
                  className=""
                  src={call}
                  width="100%"
                />
              </Col>
            </div>
          </Row>
          <Divider />
          <Row>{items}</Row> */}
        </div>
      );
    }
  }
  
  export default MaszynyPionowe;
  