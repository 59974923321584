import {
  NavItem,
  Navbar,
  Icon,
  Button,
  Col,
  CardTitle,
  Card,
  Row,
  CardPanel,
  MediaBox,
  Divider,
  Modal,
  DatePicker,
  CollapsibleItem,
  Toast,
  Collapsible,
  Form,
} from "react-materialize";
import "materialize-css";
import React, { Component } from "react";
import ListaWzor from "./ListaWzor";
import emailjs from "@emailjs/browser";
import MetaTags from 'react-meta-tags';
import call from "./icons/call.jpg";
class MaszynyPionowe extends Component {
  constructor() {
    super();

    this.state = {
      search: "maszyna pionowa",
      idtoshow: null,
    };
  }
  sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_aso22qi",
        "template_ppuo1ll",
        e.target,
        "user_iiHIuApii5dTFW9DZsq3i"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  handleClick = (event) => {
    let keyid = event.target.id;
    this.setState({ idtoshow: keyid });
    console.log(keyid);
  };

  handleClickWithId = (event, id) => {
    this.setState({ idtoshow: id });
    window.scrollTo(0, 0);
  };

  searchSpace = (event) => {
    let keyword = event.target.value;
    this.setState({ search: keyword });
  };

  OnClinkSearch = (event, id) => {
    this.setState({ search: id });
  };

  render() {
    const items = ListaWzor.filter((data) => {
      if (this.state.search == null) return data;
      else if (
        // data.plname.toLowerCase().includes(this.state.search.toLowerCase())
        // ||
        // data.keyword
        //   .toLowerCase()
        //   .includes(this.state.search.toLowerCase()) ||
        // data.plopakowania
        //   .toLowerCase()
        //   .includes(this.state.search.toLowerCase()) ||
        data.rodzaj.toLowerCase().includes(this.state.search.toLowerCase())
      ) {
        return data;
      }
    }).map((data) => {
      return (
        <div>
          
          <div>
            <Col m={5} s={12} push="m1">
              <Card
                className="kartyproduktow"
                actions={[
                  <Row>
                    <Col>
                      <Modal
                        actions={[]}
                        className="modaleProdukty"
                        bottomSheet={false}
                        fixedFooter={false}
                        id={data.plname}
                        open={false}
                        options={{
                          dismissible: true,
                          endingTop: "2%",
                          inDuration: 250,
                          onCloseEnd: null,
                          onCloseStart: null,
                          onOpenEnd: null,
                          onOpenStart: null,
                          opacity: 0.5,
                          outDuration: 250,
                          preventScrolling: true,
                          startingTop: "2%",
                        }}
                        trigger={
                          <div className="center">
                            <Button large className="orange " node="button">
                              Wiecej informacji
                            </Button>
                          </div>
                        }
                      >
                        <div>
                          <Row>
                            <Col>
                              <Col m={6} s={12}>
                                {/* <Card className=" "> */}
                                <Row>{data.imgglowny}</Row>
                                {/* </Card> */}
                              </Col>{" "}
                              <Col m={6} s={12} push="">
                                <Row>
                                  {/* <Card className=""> */}
                                  <Col m={12} s={12} push="">
                                    <h5>{data.plname}</h5>
                                    <Divider />
                                  </Col>
                                  <Col m={12} s={12} push="">
                                    <h5>Opis:</h5>
                                    <br />
                                    <p align="justify">{data.pldescription}</p>
                                  </Col>
                                  <Col m={12} s={12} push="">
                                    <h5>Opakowania:</h5>
                                    <br />
                                    <p align="justify">{data.plopakowania}</p>
                                  </Col>
                                  <Col m={12} s={12} push="">
                                    <h5>Produkty:</h5>
                                    <br />
                                    <p align="justify">{data.plprodukty}</p>
                                  </Col>

                                  <Row>
                                    <Row></Row>
                                    <Divider />
                                    <Row>
                                      <div className="center">
                                        <Modal
                                          actions={[
                                            <Button
                                              large
                                              modal="close"
                                              node="button"
                                              waves="orange"
                                              className="orange center"
                                            >
                                              Close
                                            </Button>,
                                          ]}
                                          bottomSheet={false}
                                          fixedFooter={false}
                                          header={data.plname}
                                          id={data.imgglowny}
                                          open={false}
                                          options={{
                                            dismissible: true,
                                            endingTop: "10%",
                                            inDuration: 250,
                                            onCloseEnd: null,
                                            onCloseStart: null,
                                            onOpenEnd: null,
                                            onOpenStart: null,
                                            opacity: 0.5,
                                            outDuration: 250,
                                            preventScrolling: true,
                                            startingTop: "4%",
                                          }}
                                          // root={[object HTMLBodyElement]}
                                          trigger={
                                            <Button
                                              large
                                              className="orange"
                                              node="button"
                                            >
                                              Złóż zapytanie ofertowe
                                            </Button>
                                          }
                                        >
                                          <form
                                            className="contact-form"
                                            onSubmit={this.sendEmail}
                                          >
                                            {" "}
                                            <input
                                              type="text"
                                              name="from_name"
                                            />
                                            <label>Imię i nazwisko</label>
                                            <input type="text" name="firma" />
                                            <label>Firma</label>
                                            <input
                                              value={data.plname}
                                              type="text"
                                              name="maszyna"
                                            />
                                            <label>Maszyna</label>
                                            <input
                                              type="email"
                                              name="user_email"
                                            />{" "}
                                            <label>Email</label>
                                            <input
                                              type="text"
                                              name="produkt"
                                            />{" "}
                                            <label>Państwa produkt</label>
                                            <input type="text" name="message" />
                                            <label>
                                              Wiadomość, np. zapytanie o rodzaj
                                              maszyny dla konkretnego produktu
                                            </label>
                                            <div className="center">
                                              <Toast
                                                className="white center z-depth-0"
                                                options={{
                                                  classes: "center",  
                                                  html: "Wysłano wiadomość.",
                                                }}
                                              >
                                                <input
                                                  className="btn-large orange white-text"
                                                  type="submit"
                                                  value="wyślij"
                                                />
                                              </Toast>
                                            </div>
                                          </form>
                                        </Modal>
                                        <br />
                                        <br />
                                        <a
                                          className="btn-large orange "
                                          aria-label="yourdescription" href="tel:+48123454397"
                                        >
                                          Zadzwon
                                        </a>
                                        <Button
                                          large
                                          className="grey darken-3"
                                          modal="close"
                                          node="button"
                                          waves="green"
                                        >
                                          Zamknij
                                        </Button>
                                      </div>
                                    </Row>
                                  </Row>
                                  {/* </Card> */}
                                </Row>
                              </Col>
                            </Col>
                          </Row>
                        </div>
                      </Modal>
                    </Col>
                    <h5 className="right">{data.plname}</h5>
                  </Row>,
                ]}
              >
                <div align="center">{data.imgglowny}</div>
              </Card>
            </Col>
          </div>
        </div>
      );
    });
    const pickeditem = ListaWzor.filter((data) => {
      if (this.state.idtoshow == null) return null;
      else if (data.id === this.state.idtoshow) {
        return data;
      }
    }).map((data) => {
      return (
        <div>
          <div>
            <Row>
              <Col>
                <Col m={12} s={12} push="">
                  <Row>
                    <Card className="klienci z-depth-3 ">
                      <Row >
                        <h5>{data.plname}</h5>
                        <div align="center">{data.imgglowny}</div>
                        <Divider />
                      </Row>
                      <Row>
                        <h5>Opis:</h5>
                        <br />
                        <p className="pjustify">{data.pldescription}</p>
                      </Row>
                      <Row>
                        <br />
                        <br />
                        <h5>Opakowania:</h5>
                        <br />

                        <img src={data.imgopakowanie1} width="20%" />
                        <img src={data.imgopakowanie2} width="20%" />
                        <img src={data.imgopakowanie3} width="20%" />
                        <img src={data.imgopakowanie4} width="20%" />
                        <img src={data.imgopakowanie5} width="20%" />
                        <p>{data.plopakowania}</p>
                      </Row>
                      <Row>
                        <br />
                        <br />
                        <h5>Produkty:</h5>
                        <br />
                        <p>{data.plprodukty}</p>
                      </Row>
                      <Row>
                        <br />
                        <br />
                        <br />
                        {data.daneTechnicznepl}
                        *Wydajność zależy od produktu, formatu opakowania,
                        jakości i grubości folii oraz rodzaju systemu dozowania
                      </Row>

                      <Row>
                        <Row></Row>
                        <Divider />
                        <Row></Row>
                        <Row></Row>
                        <Row>
                          <div className="center">
                            <Button
                              id={data.plname}
                              className="orange "
                              node="button"
                            >
                              Złóż zapytanie ofertowe
                            </Button>
                          </div>
                        </Row>
                      </Row>
                    </Card>
                  </Row>
                </Col>
              </Col>
            </Row>
          </div>
        </div>
      );
    });

    return (
      <div>
       <MetaTags>
        <meta name="title" content="Pionowe maszyny pakujące"/>
<meta name="description" content="Madejpak posiada w swojej ofercie pionowe maszyny pakujące, które tworzą opakowanie z rolki z folii jak również pionowe maszyny  pakujące pakując w worki papierowe. Zapraszamy do kontaktu i zapoznania się z naszą ofertą."/>
<meta name="keywords" content="paletyzacja, madejpak, robot, paletyzator, system paletyzacji, podajnik, owijarka, desztapler, magazynek palet, paleta, owijarka, owijarka talerzowa, owijarka pierścieniowa, maszyna pionowa, maszyna pakująca, automatyczna pionowa maszyna pakująca, automatyczna maszyna pakująca, VFFS machine, vertical machine, FFS machine, form-fill-seal machine, Essegi, Concetti, maszyna do zamykania worków, pillow bag, block bottom, pillow bag with gussets, paper bags, opakowania"/>
<meta name="robots" content="index, follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
<meta name="revisit-after" content="2 days"/>
<meta name="author" content="Karolina Madej"></meta></MetaTags>
        <Row>
          <Col m={5} s={12} push="m1">
            <Collapsible accordion={false}>
              <CollapsibleItem
                expanded={false}
                header="Pionowe maszyny pakujące (więcej informacji)"
                node="div"
              >
                <div className="black-text">
                  <h5>
                    {" "}
                    W szerokim asortymencie firmy Madej Pak znajdują się
                    nowoczesne pionowe maszyny pakujące o solidnej i wytrzymałej
                    konstrukcji. Oferowane przez nas maszyny doskonale
                    sprawdzają się w pakowaniu produktów spożywczych (mrożone
                    owoce i warzywa, ryby, chrupki, popcorn, kukurydza, kasza,
                    mąka) jak i produktów z branży agro (trawy, nasiona, torf,
                    kora, ziemia, kamienie, węgiel, pelet) czy chemicznej (
                    nawozy, granulaty, zaprawy, kleje). Nasza oferta jest
                    różnorodna i zawiera wiele urządzeń o odmiennych
                    parametrach, dlatego model maszyny oraz ssytem dozujący
                    zawsze dobieramy stosownie do produktu, oczekiwanej
                    wydajności a także do materiału pakującego. Zapraszamy do
                    zapoznania się z ofertą naszej firmy oraz odwiedzenia stron
                    naszych partnerów Concetti oraz Essegi
                  </h5>
                </div>
              </CollapsibleItem>
              <CollapsibleItem
                expanded={false}
                header="Jak są zbudowane i w jaki sposób działają pionowe maszyny pakujące (więcej informacji)"
                node="div"
              >
                <h5>
                  Podstawą zasadą działania pionowych maszyn pakujących jest
                  formowanie, napełnienie i zgrzewanie opakowania. Maszyny
                  pionowe mogą pracować zarówno z gotowymi workami, workami
                  papierowymi jak również wykonać opakowanie z rolki z folii.
                  Cechą charakterystyczną pionowych maszyn pakujących jest to,
                  że każda maszyna w zależności od potrzeb klienta jest
                  indywidualnie dobierana do każdego projektu na podstawie karty
                  charakterystyki oraz wymiarów produktów. Zasada działania jest
                  bardzo prosta, produkt jest ważony, po osiągnięciu
                  odpowiedniej wagi jest zrzucany do worka, który po napełnieniu
                  jest zamykany.
                </h5>
              </CollapsibleItem>
              <CollapsibleItem
                expanded={false}
                header="Gdzie stosuje się  pionowe maszyny pakujące (więcej informacji)"
                node="div"
              >
                <h5>
                  Maszyny pionowe idealnie wkomponowują się w branżę spożywczą
                  przy pakowaniu produktów takich jak mrożone owoce czy warzywa,
                  ryby, chrupki, popcorn, kukurydza, kasza, mąka, ale też kawa,
                  herbata, cukier czy makarony. Świetnie nadadzą się także do
                  pakowania produktów rolniczych, czyli trawy, nasion, torfu,
                  ziemi czy paszy. Podobnie jeśli chodzi o branżę wydobywczą czy
                  chemiczną. W końcu pakuje się także kamienie, węgiel, pellet
                  czy nawozy, granulaty, zaprawy albo różnego rodzaju kleje.
                  Zatem zastosowanie maszyn pakujących pionowo jest przeogromne
                  i warto z niego skorzystać.
                </h5>
              </CollapsibleItem>
            </Collapsible>
          </Col>
          <div className="hide-on-small-only">
            <Col m={2} s={12} push="m1">
              <h5>Zadzwoń do nas, pomożemy Ci wybrać odpowiedni produkt.</h5>
            </Col>
            <Col m={1} s={12} push="m1">
              <br />
              <br />
              <br />
              <div>
                <a className="btn-large orange " aria-label="yourdescription" href="tel:+48123454397">
                  Zadzwoń
                </a>
              </div>
            </Col>
            <Col m={1} s={12} push="m1">
              <img className="" src={call} width="100%" />
            </Col>
          </div>
        </Row>
        <Divider />
        <Row>{items}</Row>
      </div>
    );
  }
}

export default MaszynyPionowe;
