const ikonybranze = [
    {
        ikona:"https://cdn.onlinewebfonts.com/svg/img_415179.png",
      },{
        ikona:"https://cdn.onlinewebfonts.com/svg/img_415179.png",
      },{
        ikona:"https://cdn.onlinewebfonts.com/svg/img_415179.png",
      },{
        ikona:"https://cdn.onlinewebfonts.com/svg/img_415179.png",
      },{
        ikona:"https://cdn.onlinewebfonts.com/svg/img_415179.png",
      },{
        ikona:"https://cdn.onlinewebfonts.com/svg/img_415179.png",
      },{
        ikona:"https://cdn.onlinewebfonts.com/svg/img_415179.png",
      },{
        ikona:"https://cdn.onlinewebfonts.com/svg/img_415179.png",
      },{
        ikona:"https://cdn.onlinewebfonts.com/svg/img_415179.png",
      },{
        ikona:"https://cdn.onlinewebfonts.com/svg/img_415179.png",
      },
    
]
export default ikonybranze